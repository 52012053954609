import React from 'react'
import AddIcon from '../../../images/add.svg'
import MinusIcon from '../../../images/minus.svg'
import Input from '../../../components/Input'

export default function OfferInput({type=0,value='',updateOfferType,updateOfferValue,error=null}) {

    const onPress = (e)=>
    {
        const re = /^[0-9\b]+$/;
          if (e.target.value === '' || re.test(e.target.value)) {
            return true;
          }
          else return false;
    }


  return (
    <div className='flex  w-auto ml-auto border rounded-md overflow-hidden'>

        <div>
            {/* <Input value={value} setValue={value=>setValue(value)}/> */}
            <input type={'text'} value={value} class={`${error ? 'error-input' : 'w-[58px] border-0  border-gray-300 h-[32px] text-center text-[13px] focus:ring-0 focus:outline-0 focus:border-[#2ac08b]'}`} 
 onChange={(e)=>updateOfferValue(e.target.value)} onKeyDown={onPress}/>
        </div>
        <div>
            <select onChange={(e)=>updateOfferType(e.target.value)} defaultValue={type} value={type} class='w-[40px] h-[100%] border-0 px-0 rounded-none border-l focus:ring-0 focus:outline-0 focus:border-gray-300 font-bold'>
                <option value={'percentage'}>&#37;</option>
                <option value={'amount'}>&#8377;</option>
            </select>
        </div>
    </div>
  )
}
