import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
    name:'settings',
    initialState:{
        loanProviderTypes:[],
        approvalTimes:[],
        ltvs:[],
        productTypes:[],
        productSchemes:[],
        productMotorTypes:[],
        productCategories:[],
        productUsages:[]
    },
    reducers:{
        update:(state,action)=>
        {
            return{
                agentVisibilityRules:action.payload.agentVisibilityRules,
                loanProviderTypes:action.payload.loanProviderTypes,
                approvalTimes:action.payload.approvalTimes,
                ltvs:action.payload.ltvs,
                loanProviderTypes:action.payload.loanProviderTypes,
                productSchemes:action.payload.productSchemes,
                productMotorTypes:action.payload.productMotorTypes,
                categories:action.payload.categories,
                productUsages:action.payload.productUsages,
              }
        }
    }
})

export const {update} = settingsSlice.actions

export default settingsSlice.reducer