import React, { useEffect, useState } from 'react'
import Logo from '../../images/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import InputGroup from '../../components/InputGroup'
// import Toggle from '../../components/Toggle'
import axios from 'axios'
import { authAxios, publicAxios } from '../../helpers/axios'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../redux/slice/authSlice'
import { toast } from 'react-toastify'
import { InfoToast } from '../../helpers/toasters'

export default function Login() {

    const [data,setData] = useState({adminEmail:'',password:''})
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState(null)
    const authInfo = useSelector(state=>state.auth)

    useEffect(()=>
    {
        if(authInfo.token && authInfo.isLoggedIn) return navigate('/')
        
    },[])
    const onSubmit = async(e)=>
    {
        try {
            setError(false)
            e.preventDefault();
            setLoading(true)
            let res = await authAxios.post('login/admin',data);
            if(res.data.status === 200)
            {
                InfoToast('Logged In')
                dispatch(login({...res.data.data,token:res.data.token}))
                if(authInfo.token && authInfo.isLoggedIn)
                {
                    await authAxios.interceptors.request.use(
                        async(config) => {
                            config.headers.Authorization = authInfo.token;
                            return config;
                        }
                        ,(error) => {
                            InfoToast('Login Failed')
                            return Promise.reject(error)
                        });
                    }
                    return navigate('/')
            }
            } catch (error) {
                setLoading(false)
                console.log('error',error.response.data)
                InfoToast(error.response.data.status === 500 ? 'Email/Password Incorrect' : 'Error Logging In')
                setError(error.response.data.message)
                // toast(error.response.data.message);
                
            }
                // else {
                //     setLoading(false)
                //     setError(res.errors.invalid_credentials)
                // }
            }

            return (
                <div className='flex md:h-screen justify-center'>
        <div className='md:col-span-3 md:h-screen w-full'>
            <div className='h-auto md:h-full w-full flex py-8'>
                <div className="my-auto  mx-auto w-full max-w-md px-12 py-12 rounded-lg bg-white border-gray-100 border">
                    <img src={Logo} className='mx-auto w-auto h-12 mb-2'/>
                    <h1 className='text-xl font-bold text-center'>Hi, Welcome Back</h1>
                    <p className='text-sm text-gray-500 text-center'>to the kajah tea admin portal</p>
                    
                    <form className='mt-12 w-full' onSubmit={onSubmit}>
                        <InputGroup type='email' label="Email Address" placeholder="johndoe@workemail.com" value={data.adminEmail} setValue={(value)=>setData(data=>({...data,adminEmail:value}))} required={true}/>
                        <InputGroup type='password' label="Password" placeholder="8+ Characters required"  value={data.password} setValue={(value)=>setData(data=>({...data,password:value}))} required={true}/>
                        <div className='flex justify-between'>
                            <Link to='/forgot-password' className='text-sm text-blue-700 font-medium'>Forgot Password</Link>
                        </div>
                        <div className='relative mt-4'>
                            <button type='submit' className='btn-xl w-full mt-4 mb-6' disabled={loading}>{loading ? 'Logging In ': 'Login'}</button>
                            {error ? <div class="w-full absolute left-0 bottom-0 flex items-center pointer-events-none overflow-hidden h-4">
                                <div className='text-center w-full'>
                            <p className='text-red-600 h-[20px] text-[14px] tracking-tight font-medium'>{error}</p>
                                    </div>
                        </div> : null}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}
