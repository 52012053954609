import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import ManageDistributor from './components/ManageDistributor'
import FilterIcon from '../../images/filter.svg'
import FilterActiveIcon from '../../images/filteractive.svg'
import SortIcon from '../../images/sort.svg'
import RightIcon from '../../images/right.svg'
import { LIMIT } from '../../helpers/constants'
import DistributorFilter from './components/DistributorFilter'
import { getDateFormat } from '../../helpers/utils'
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'


export default function Distributor() {

    const [searchText,setSearchText] = useState('')
    const [distributors,setDistributors] = useState([])
    const [showCreate,setShowCreate] = useState(false)

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(0)

    const [filters,setFilters] = useState({routeId:null,cityId:null,status:null})
    const [showFilters,setShowFilters] = useState(false)
    const [sort,setSort] = useState('name');

      

    const onFilterSubmit = (filters)=>
    {
        try 
        {
            setFilters({...filters})
            setCount(5)
            setOffset(0)
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const clearFilter = ()=>
    {
        try 
        {
            setFilters({routeId:null,cityId:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }

    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowCreate(true)}>Add Distributor</button>
        </div>
    }

    async function getDistributors(){

        try 
        {
                let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
                if(searchText) query+= `&search=${searchText}`;
                if(filters.cityId) query+=`&cityId=${filters.cityId.id}`
                if(filters.routeId) query+=`&routeId=${filters.routeId.id}`
                if(filters.status) query+=`&status=${filters.status}`
                // if(sort.status) query+=`&status=${filters.status}`
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/distributor?populate=true${query}`)

            // let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/distributor?populate=true`)
            if(res.data.data)
            {
                setDistributors(res.data.data)
                setCount(res.data.count)
            } 
        } catch (error) {
            toast('Error getting distributors')
        }
    }
    useEffect(()=>
    {
        getDistributors();
    },[filters,searchText,offset,sort])

    useEffect(()=>
    {
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCityFilters(res.data.data)
        }
        getCities()
    },[])

    useEffect(()=>
    {
        async function getRoutes(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?routeCity=${selectedFilters.city}`)
            if(res.data.data)  setRouteFilters(res.data.data)
        }
        if(selectedFilters.city !== '') getRoutes()
        else {
            setRouteFilters([]);
            setSelectedFilters(data=>({...data,route:''}))
        }
    },[selectedFilters.city])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            console.log(data)
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/distributor`,{...data}) 
            if(res.data.data)
            {
                await getDistributors()
                setShowCreate(false)
            }
            else toast('error updating retailer')
        } catch (error) {
            console.log(error)
            toast(error.response)
        }
    }

  return (
    <>
        <Header title={'Distributor Management'} RightContent={RightContent}/>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
            <div className='flex'>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Distributor'} label={'Search Distributor'} />
                <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
            </div>
            <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(filters.cityId || filters.routeId || filters.status) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div>
        </div>
        <div className='flex flex-1 bg-white w-full overflow-scroll'>
            <table className='table-auto w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full flex'>
                    <td className='flex-1'><p onClick={()=>onSortPress('name')} className='cursor-pointer flex items-center hover:text-black'>Company{sort === 'name' || sort === '-name'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td className='w-[40%]'><p onClick={()=>onSortPress('city')} className='cursor-pointer flex items-center hover:text-black'>City/Route {sort === 'city' || sort === '-city'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    
                    <td className='w-[10%]'><p onClick={()=>onSortPress('phone')} className='cursor-pointer flex items-center hover:text-black'>Last Order {sort === 'phone' || sort === '-phone'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td className='w-[10%]'><p onClick={()=>onSortPress('order')} className='cursor-pointer flex items-center hover:text-black'>Orders {sort === 'order' || sort === '-order'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td className='w-[5%]'><p onClick={()=>onSortPress('points')} className='cursor-pointer flex items-center hover:text-black'>Points {sort === 'points' || sort === '-points'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td className='w-[5%]'><p onClick={()=>onSortPress('weight')} className='cursor-pointer flex items-center hover:text-black'>Weight {sort === 'weight' || sort === '-weight'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td className='w-[5%]'><p onClick={()=>onSortPress('target')} className='cursor-pointer flex items-center hover:text-black'>Target {sort === 'target' || sort === '-target'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td className='w-[5%]'><p onClick={()=>onSortPress('status')} className='cursor-pointer flex items-center hover:text-black'>Status {sort === 'status' || sort === '-status'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        distributors.map((item,index)=>
                        {
                            return <tr onClick={()=>navigate(`/distributors/${item.id}`)}>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.distributorCompanyName}</p>
                                        <p className='text-[13px] text-gray-400 my-0'>{item.distributorName}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                {/* <div>
                                        <p className='text-sm font-regular my-0'>{item.distributorCity?.cityName}</p>
                                        <p className='text-[13px] text-gray-400 my-0'>{item.distributorRoute?.routeName}</p>
                                    </div> */}
                                    {item.cities && item.cities.length >= 1 && item.cities[0].cityInfo?.cityName ? <CityRouteInfo id={item.id} data={item.cities}/> : <p className='text-sm font-regular my-0'>Not Available</p>}
                                </td>
                                <td>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.lastPurchase ? getDateFormat(item.lastPurchase) : 'Not Available'}</p>
                                    </div>
                                </td>
                                <td>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.totalOrders ? item.totalOrders : 'Not Available'}</p>
                                    </div>
                                </td>
                                <td>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.totalPoints ? item.totalPoints : 'Not Available'}</p>
                                    </div>
                                </td>
                                <td>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.totalWeight ? item.totalWeight : 'Not Available'}</p>
                                    </div>
                                </td>
                                <td>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.distributorTarget ? item.distributorTarget : 'Not Available'}</p>
                                    </div>
                                </td>

                                <td className='capitalize'>
                                <div>
                                        <p className={`text-[13px] font-semibold px-3 inline-block py-1 rounded-md ${parseInt(item.distributorActive) ===  1 ? 'bg-[#39C7A5] bg-opacity-50 text-green-700 ' : 'text-red-700 bg-red-200 bg-opacity-10'}}`}>{parseInt(item.distributorActive) ===  1 ? 'Active' : 'Inactive'}</p>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showCreate ? <ManageDistributor onClose={setShowCreate} onSubmit={onSubmit}/> : null}
        { showFilters ? <DistributorFilter onSubmit={onFilterSubmit} filters={filters} onClose={setShowFilters} clearFilter={clearFilter}/> : null}
    </>
  )
}


const CityRouteInfo = ({ data ,id}) => {


    let routes = data.flatMap(item => item.routeIds.map(routeId => {
        const routeInfo = routeId.routeName; // Adjust this based on your route information
        return routeInfo;
    }));

    console.log(routes)
    return (
      <div>
        <p className='text-sm font-regular my-0 flex items-start' >{data.length > 0 ? data[0].cityInfo.cityName : 'Not Available'}{data.length > 1 ?<span data-tooltip-id={`${id}-city`} className='ml-1 text-[9px] font-semibold h-[16px] w-[16px] flex justify-center items-center text-center bg-black text-white rounded-lg'>{data.length > 1 ? ` +${data.length - 1}` : ''}</span> : null}
        </p>
        <p className='text-[13px] text-gray-400 my-0' >{data.length > 0 ? data[0].routeIds[0].routeName : 'Not Available'}{routes.length > 1 ?<span data-tooltip-id={`${id}-route`} className='inline px-1 py-1 self-end ml-1 text-[9px] font-semibold w-[100%] h-[100%] !max-h-[16px] !max-w-[16px]  justify-center items-center text-center bg-black text-white rounded-full'>{routes.length > 1 ? `+${routes.length - 1}` : ''}</span> : null}</p>
  
          <Tooltip id={`${id}-city`} effect="solid" place="top" globalEventOff="click" clickable>
            {data.map((item, index) => (
              <p className='text-xs' key={index}>{item.cityInfo.cityName}</p>
            ))}
          </Tooltip>

          <Tooltip id={`${id}-route`} effect="solid" place="top" globalEventOff="click" clickable>
            {
                routes.map((item, index) => (
                    <p className='text-xs' key={index}>{item}</p>
                ))
            }
          </Tooltip>
      </div>
    );
  };

