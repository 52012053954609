import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import SearchInput from '../../../components/SearchInput'
import DatePicker from 'react-datepicker';
import moment from 'moment'
import { getDateFormat, getDayFromDate } from '../../../helpers/utils'
// import TeamLeaderboard from './components/TeamLeaderboard'

export default function SalesRoute({id}) {

    const [searchText,setSearchText] = useState('')
    const [schedules,setSchedules] = useState([])
    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const [selectedFilter,setSelectedFilter] = useState('')
    const [scheduleDate,setScheduleDate] = useState(new Date())
    const navigate = useNavigate()
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})

    async function getSchedules(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/schedules/rep?monthYear=${moment(scheduleDate).format('MM-YYYY')}&salesRepId=${id}&populate=true`)
            if(res.data.data) setSchedules(res.data.data)
        } catch (error) {
            toast('Error getting retailer')
        }
    }
    useEffect(()=>
    {
        getSchedules();
    },[scheduleDate])


  return (
    <div className='w-full'>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white w-full'>
            <div className='flex'>
            {/* <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Sales Rep'} label={'Search Team'} /> */}
            </div>
            <div className='flex'>
                {/* <FilterSelect placeholder={'Select Month'} options={routeFilters} value={selectedFilter} customLabel={'routeName'} customValue={'id'} setValue={(value)=>setSelectedFilter(value)} padding={false}/> */}
                {/* <DateRangePicker onChange={item => setDateInfo([item.selection])} showSelectionPreview={true} moveRangeOnFirstSelection={false} months={1} ranges={dateInfo} direction="horizontal"/> */}
                {/* <input type='date' value={scheduleDate} onkeydown="return false" onChange={(e)=>setScheduleDate(e.target.value)} className='px-2 py-4 placeholder-gray-400 bg-transparent focus:ring-0 h-[100%] border-0 border-transparent border-l focus:outline-0 text-[13px] border-l-gray-300'/> */}
                <DatePicker selected={scheduleDate} onKeyDown={(e) => {
    e.preventDefault();
}} dateFormat={'MMM-yyyy'} showMonthYearDropdown={true} className={'h-full w-[100px] text-[13px] cursor-pointer border-l px-4 py-3'} onChange={(date) => setScheduleDate(date)}       showMonthYearPicker
showFullMonthYearPicker
showTwoColumnMonthYearPicker/>
            </div>
        </div>
        <div className='flex flex-1 bg-white w-full'>
            <table className=' w-full'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Date</p></td>
                    <td><p>Day</p></td>
                    <td><p>Route</p></td>
                    <td><p>Total Orders</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        schedules.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{getDateFormat(item.scheduleDate)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{getDayFromDate(item.scheduleDate)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.routeName ? item.routeName : 'Not Assigned'}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.orderCount}</p>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}

