import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import MultiSelect from 'react-select'
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"

export default function ManageTeam({onClose,onSubmit,updateData,edit,submitting})
{
    const [manageData,setManageData] = useState({teamName:'',teamRepId:'',cityId:'',status:1,teamTarget:null,cities:[]})
    const [cities,setCities] = useState([])
    const [salesRep,setSalesRep] = useState([])
    const formName = 'editTeam';
    useEffect(()=>
    {
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCities(res.data.data)
        }
        async function getTeamInfo(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/teams/${edit}?populate=true`)
            console.log('team info',res)
            let filtered = cities.filter(obj => res.data.data.cities.includes(obj.id));
            setManageData({teamName:res.data.data.teamName,teamRepId:res.data.data.teamRepId ? res.data.data.teamRepId.id : null,cityId:res.data.data.cityId,status:res.data.data.status,teamTarget:res.data.data.teamTarget,cities:res.data.data.cities})
        }
        getCities()
        getTeamInfo()
    },[])

    useEffect(()=>
    {
        console.log('cities',manageData)
    },[manageData])

    useEffect(()=>
    {
        async function getRoutes(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/salesrepresentative?repCity=${manageData.cityId}`)
            setSalesRep(res.data.data)
        }
        if(manageData.cityId !== '') getRoutes()
    },[manageData.cityId])



    return <><Popup submitting={submitting} title={edit ? 'Update Team' : 'Create Team'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='grid grid-cols-2 gap-x-3 gap-y-0 w-full'>
            <div>
                <label>Team Name*</label>
                <Input type='text' placeholder={'Enter Team Name'} value={manageData.teamName} required={true} setValue={(value)=>setManageData(data=>({...data,teamName:value}))}/>
            </div>
            <div>
                <label>Team Lead</label>
                <Select placeholder={'Select Sales Rep'} customLabel={'firstName'} required={false} options={salesRep} customValue={'id'} value={manageData.teamRepId} setValue={(value)=>setManageData(data=>({...data,teamRepId:value}))}/>
            </div>
            <div>
                <label>Team Target*</label>
                <Input type='text' placeholder={'Enter Team Target'} value={manageData.teamTarget} required={true} setValue={(value)=>setManageData(data=>({...data,teamTarget:value}))}/>
            </div>
            <div>
                <label>Status*</label>
                <Select placeholder={'Select Status'} options={[{name:'Active',value:1},{name:'Inactive',value:0}]}  value={parseInt(manageData.status)} setValue={(value)=>setManageData(data=>({...data,status:value}))}/>
            </div>
            <div className="col-span-2">
                <label>City*</label>
                <MultiSelect classNames={{control:()=>'border !border-gray-200 !bg-[#fcfcfc]'}}  value={manageData.cities} getOptionLabel={(item)=>item.cityName} onChange={(value)=>setManageData(data=>({...data,cities:[...value]}))} getOptionValue={(item)=>item.id} isMulti name="" options={cities} className="basic-multi-select mb-10" classNamePrefix="multi-selection"/>
            </div>
        </form>
    </Popup>
    </>
}