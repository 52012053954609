import axios from 'axios';

const authAxios = axios.create({
baseURL:process.env.REACT_APP_BASE_URL
})

const publicAxios = axios.create({
baseURL:process.env.REACT_APP_BASE_URL
})

export {authAxios,publicAxios}