import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import MultiSelect from 'react-select';
import CropPopup from "../../../components/CropPopup"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"

export default function ManageRetailer({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState(!edit ? {retailerShopImage:'',retailerShopName:'',retailerGst:'',retailerName:'',retailerContactNumber:'',retailerCity:'',retailerRoute:'',retailerArea:'',retailerActive:1,retailerEmail:`test${Math.floor(Math.random() * 2000) + 1}@gmail.com`,retailerApproved:true,retailerAddress:''} : {...updateData,retailerCity:updateData.retailerCity.id,retailerRoute:updateData.retailerRoute.id,retailerAddress:updateData.retailerAddress,retailerGst:updateData.retailerGst,retailerActive:parseInt(updateData.retailerActive)})
    const [cities,setCities] = useState([])
    const [routes,setRoutes] = useState([])
    const [distributors,setDistributors] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState()

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setManageData(data=>({...data,retailerImage:null}))
        //     setCroppedImage(event.target.files[0])
        //     // setImage(event.target.files[0]);
        //     // setShowCrop(true)
        // setImage(URL.createObjectURL(event.target.files[0]));
        setImage(URL.createObjectURL(event.target.files[0]));
            setCroppedImage(event.target.files[0])
        }
        }

    useEffect(()=>
    {
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCities(res.data.data)
        }
        getCities()
    },[])

    useEffect(()=>
    {
        // setDistributors([])
        // setManageData(data=>({...data,retailerDistributors:[]}))
        // setManageData(data=>({...data,retailerDistributors:[]}))
        async function getRoutes(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?routeCity=${manageData.retailerCity}`)
            setRoutes(res.data.data)
        }
        if(manageData.retailerCity !== '') getRoutes()
    },[manageData.retailerCity])

    // useEffect(()=>
    // {
    // // setDistributors([])
    // // setManageData(data=>({...data,retailerDistributors:[]}))
    //     async function getDistributors(){
    //         let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/distributor?routeId=${manageData.retailerRoute}`)
    //         setDistributors(res.data.data)
    //     }
    //     if(manageData.retailerRoute !== '') getDistributors()
    // },[manageData.retailerRoute])



    return <><Popup size="xl" title={edit ? 'Update Retailer' : 'Create Retailer'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData},croppedImage)} id={formName} className='w-full'>
            <div className="col-span-4">
                    <ImageInput image={manageData.retailerImage && manageData.retailerImage!=='' ? `${manageData.retailerImage}` : image} setImage={onImageChange} />
                </div>
                <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div className="grid grid-cols-4 gap-x-3 gap-y-0 w-full pr-4 border-r border-r-gray-200">
            <div className="col-span-2">
                <label>Shop Name</label>
                <Input type='text' placeholder={'Enter Company Name'} value={manageData.retailerShopName} setValue={(value)=>setManageData(data=>({...data,retailerShopName:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Retailer Name</label>
                <Input type='text' placeholder={'Enter Company Name'} value={manageData.retailerName} setValue={(value)=>setManageData(data=>({...data,retailerName:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Phone Number (+91)</label>
                <Input type='text' placeholder={'Enter Phone Number'} value={manageData.retailerContactNumber} setValue={(value)=>setManageData(data=>({...data,retailerContactNumber:value}))}/>
            </div>
            <div className="col-span-2">
                <label>City</label>
                <Select placeholder={'Select City'} customLabel={'cityName'} options={cities} customValue={'id'} value={manageData.retailerCity} setValue={(value)=>setManageData(data=>({...data,retailerCity:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Route</label>
                <Select placeholder={'Select Route'} customLabel={'routeName'} options={routes} customValue={'id'} value={manageData.retailerRoute} setValue={(value)=>setManageData(data=>({...data,retailerRoute:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Area</label>
                <Input type='text' placeholder={'Enter area'} value={manageData.retailerArea} setValue={(value)=>setManageData(data=>({...data,retailerArea:value}))}/>
            </div>
            <div className="col-span-4">
                <label>Address</label>
                <textarea className="h-[40px] w-full text-input mb-4" placeholder={'Enter Address'} value={manageData.retailerAddress} onChange={(e)=>setManageData(data=>({...data,retailerAddress:e.target.value}))}/>
            </div>
            <div className="col-span-1">
                <label>Status</label>
                <Select size="sm" placeholder={'Select Status'} options={[{name:'Active',value:1},{name:'Inactive',value:0}]}  value={manageData.retailerActive} setValue={(value)=>setManageData(data=>({...data,retailerActive:value}))}/>
            </div>
            <div className="col-span-3">
                <label>GSTIN</label>
                <Input type='text' placeholder={'Enter GSTIN Number'} value={manageData.retailerGst} setValue={(value)=>setManageData(data=>({...data,retailerGst:value}))}/>
            </div>
            </div>
            <div className="px-3">
                {/* <div>
                    <label>Distributors</label>
                <MultiSelect defaultValue={manageData.retailerDistributors} getOptionLabel={(item)=>item.distributorName} getOptionValue={(item)=>item.id} isMulti name="" options={distributors} className="basic-multi-select mb-10" classNamePrefix="select"/>
                </div> */}
                <div className='col-span-2'>
                    <Map setLocation={(lat,lang)=>setManageData(data=>({...data,retailerLat:lat,retailerLong:lang}))} defaultLocation={edit ? {lat:manageData.retailerLat,long:manageData.retailerLong} : false}/>
                </div>
            </div>
            </div>
        </form>
    </Popup>
    {showCrop ? <CropPopup image={image} setActive={setShowCrop} setImage={setCroppedImage}/>: null}
    </>
}