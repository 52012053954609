import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import Popup from '../../components/Popup'
import Input from '../../components/Input'
import EditImage from '../../images/edit.svg'
import { toast } from 'react-toastify'
import Select from '../../components/Select'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import TabGroup from '../../components/TabGroup'
import Routes from './components/Routes'
import Categories from './components/Categories'
import Cities from './components/Cities'
import Hsns from './components/Hsns'

export default function Settings() 
{
    const [showManage,setShowManage] = useState({type:null,status:false,edit:null})
    const [activeTab,setActiveTab] = useState('routes')

    
    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowManage({type:activeTab,status:true})}>Add {activeTab === 'routes' ? 'Route' : activeTab === 'cities' ? 'City' : activeTab === 'hsns' ? 'HSN' : 'Category'}</button>
        </div>
    }

  return (
    <>
    <Header title={'Settings'} RightContent={RightContent} parentTitle='' parentLink='sales-representatives'/>
    <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
        <div>
           <TabGroup setActiveTab={setActiveTab} activeTab={activeTab} options={[{label:'Routes',value:'routes'},{label:'Cities',value:'cities'},{label:'HSNs',value:'hsns'},{label:'Categories',value:'categories'}]}/>
        </div>
    </div>
    <div className='bg-white w-full flex-1 flex overflow-scroll'>
        {activeTab === 'routes' ? <Routes setShowManage={setShowManage} showManage={showManage}/> : activeTab === 'cities' ? <Cities setShowManage={setShowManage} showManage={showManage}/> : activeTab === 'hsns'  ? <Hsns setShowManage={setShowManage} showManage={showManage}/> : <Categories setShowManage={setShowManage} showManage={showManage}/>}
    </div>
</>
  )
}








