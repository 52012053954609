import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import RightIcon from '../../images/right.svg'
import FilterIcon from '../../images/filter.svg'
import FilterActiveIcon from '../../images/filteractive.svg'
import SearchInput from '../../components/SearchInput'
import DatePicker from 'react-datepicker';
import moment from 'moment'
import { LIMIT } from '../../helpers/constants'
import ScheduleFilter from './components/ScheduleFilter'
// import TeamLeaderboard from './components/TeamLeaderboard'

export default function Schedule() {

    const [searchText,setSearchText] = useState('')
    const [schedules,setSchedules] = useState([])
    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const [selectedFilter,setSelectedFilter] = useState('')
    const [scheduleDate,setScheduleDate] = useState(new Date())
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const navigate = useNavigate()
    const [showFilters,setShowFilters] = useState(false)
    

    const [selectedFilters,setSelectedFilters] = useState({cityId:'',routeId:''})

    const onFilterSubmit = (filters)=>
    {
        try 
        {
            setSelectedFilters({...filters})
            setCount(5)
            setOffset(0)
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const clearFilter = ()=>
    {
        try 
        {
            setSelectedFilters({routeId:null,cityId:null})
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    async function getSchedules(){

        try 
        {
            let query = `&scheduleDate=${moment(scheduleDate).format('DD-MM-YYYY')}&limit=${LIMIT}&offset=${offset}`;
            if(searchText) query+= `&search=${searchText}`;
            if(selectedFilters.cityId) query+=`&cityId=${selectedFilters.cityId.id}`
            if(selectedFilters.routeId) query+=`&routeId=${selectedFilters.routeId.id}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/schedules?populate=true${query}`)
            if(res.data.data) 
            {
                setSchedules(res.data.data)
                setCount(res.data.count)
            }
        } catch (error) {
            toast('Error getting retailer')
        }
    }
    useEffect(()=>
    {
        getSchedules();
    },[scheduleDate,searchText,offset,selectedFilters])



  return (
    <>
        <Header title={'Route Schedule'} RightContent={()=>{}}/>
        <div className=' flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
            <div className='flex'>
            <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Sales Rep'} label={'Search Team'} />
            <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
            </div>
            <div className='flex'>
                <DatePicker selected={scheduleDate} onKeyDown={(e) => {
    e.preventDefault();
}} dateFormat={'dd-MM-yyyy'} className={'h-full w-[100px] text-[13px] cursor-pointer border-l px-2'} onChange={(date) => setScheduleDate(date)} />
            {/* <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(selectedFilters.cityId || selectedFilters.routeId) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div> */}
            </div>
        </div>
        <div className='bg-white w-full flex-1 flex overflow-scroll'>
            <table className='table-fixed flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Name/Team</p></td>
                    <td><p>Phone/Email</p></td>
                    <td><p>Cities</p></td>
                    <td><p>Routes</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        schedules.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.salesRepId.firstName}</p>
                                        <p className='text-[13px] text-gray-400 my-0'>{item.salesRepId?.repTeam.teamName}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.salesRepId.repContactNumber}</p>
                                        <p className='text-[13px] text-gray-400 my-0 lowercase'>{item.salesRepId.repEmail}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.cities.length > 0 ? item.cities.join(', ') : 'Not Available'}</p>
                                        {/* <p className='text-[13px] text-gray-400 my-0 capitalize'>{item.repTeam ? item.repTeam?.teamName : 'Not Assigned'}</p> */}
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    {
                                        item.routeNames.length > 0 ? item.routeNames?.map((item)=>
                                        {
                                            return <p className='text-sm font-regular my-0'>{item}</p>
                                        }) : <p className='text-sm font-regular my-0'>Not Available</p>
                                    }
                                        {/* <p className='text-sm font-regular my-0'>{item.routeId ? item.routeId?.routeName : 'Not Assigned'}</p> */}
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showFilters ? <ScheduleFilter onSubmit={onFilterSubmit} filters={selectedFilters} onClose={setShowFilters} clearFilter={clearFilter}/> : null}
    </>
  )
}

