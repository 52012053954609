import React from 'react'
import Search from '../images/search.svg'

export default function TargetUI({percentage}) {
  let WidthClass = !percentage ? 'w-0' : `w-[${percentage}%]`;
  return (
    <div>
        <div className=' bg-gray-300 h-[6px] rounded-xl w-[80px]'>
            <div className={`bg-[#01B289] h-[6px] rounded-xl`} style={{width:!percentage ? '0%' : `${percentage}%` }}>

            </div>
        </div>
    </div>
  )
}
