import React, { useEffect, useState } from 'react'
import Logo from '../../images/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import InputGroup from '../../components/InputGroup'
// import Toggle from '../../components/Toggle'
import axios from 'axios'
import { authAxios, publicAxios } from '../../helpers/axios'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../redux/slice/authSlice'
import { toast } from 'react-toastify'
import { InfoToast } from '../../helpers/toasters'

export default function ForgotPassword() {

    const [data,setData] = useState({adminEmail:''})
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState(null)
    const authInfo = useSelector(state=>state.auth)

    useEffect(()=>
    {
        if(authInfo.token && authInfo.isLoggedIn) return navigate('/')
        
    },[])
    const onSubmit = async(e)=>
    {
        try {
            setError(false)
            e.preventDefault();
            setLoading(true)
            let res = await authAxios.post('/forgot-password',{email:data.adminEmail,type:'admin'});
            if(res.data.status === 200)
            {
                InfoToast('Reset Email Sent')
            }
            } catch (error) {
                setLoading(false)
                console.log('error',error.response.data)
                InfoToast(error.response.data.status === 500 ? 'Email/Password Incorrect' : 'Error Logging In')
                setError(error.response.data.message)
            }
            }

            return (
                <div className='flex md:h-screen justify-center'>
        <div className='md:col-span-3 md:h-screen w-full'>
            <div className='h-auto md:h-full w-full flex py-8'>
                <div className="my-auto  mx-auto w-full max-w-md px-12 py-12 rounded-lg bg-white border-gray-100 border">
                    <img src={Logo} className='mx-auto w-auto h-12 mb-2'/>
                    <h1 className='text-xl font-bold text-center'>Forgot Password?</h1>
                    <p className='text-sm text-gray-500 text-center'>Enter your email to get password reset email</p>
                    
                    <form className='mt-12 w-full' onSubmit={onSubmit}>
                        <InputGroup type='email' label="Email Address" placeholder="johndoe@workemail.com" value={data.adminEmail} setValue={(value)=>setData(data=>({...data,adminEmail:value}))} required={true}/>
                        <div className='flex justify-between'>
                            <Link to={'/login'} className='text-sm text-blue-700 font-medium'>Remember password? Login Now</Link>
                        </div>
                        <div className='relative mt-4'>
                            <button type='submit' className='btn-xl w-full mt-4 mb-6' disabled={loading}>{loading ? 'Sending Email ': 'Send Password Reset Email'}</button>
                            {error ? <div class="w-full absolute left-0 bottom-0 flex items-center pointer-events-none overflow-hidden h-4">
                                <div className='text-center w-full'>
                            <p className='text-red-600 h-[20px] text-[14px] tracking-tight font-medium'>{error}</p>
                                    </div>
                        </div> : null}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}
