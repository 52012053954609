import React, { createRef, useState } from 'react'
import CloseIcon from '../images/close.svg'
// import { Cropper } from 'react-cropper'
// import "cropperjs/dist/cropper.css";
import { Cropper } from 'react-mobile-cropper'
import 'react-mobile-cropper/dist/style.css'

export default function CropPopup({image,setActive,setImage}) {
    // const [image, setImage] = useState('');
  const [cropData, setCropData] = useState("#");
  const cropperRef = createRef();

  const getCroppedData = ()=>
  {
    // setImage(cropperRef.current.getCanvas()?.toBlob())
    setActive(false)
    cropperRef.current.getCanvas()?.toBlob((blob)=>{
      console.log(blob)
      if(blob) setImage(blob)
    },'image/png')
    // let imageBlob = cropperRef.current.getCanvas()?.toDataURL();
    // setImage(imageBlob)
  }
  return (
    <div className='bg-black bg-opacity-70 fixed z-[999] w-[100%] h-full left-0 top-0 flex justify-center items-center'>
    <div className='w-[480px] max-w-full bg-white   top-0 h-auto right-[320px] py-2 rounded-md'>
        <div className='flex px-8 py-4 justify-between items-center'>
            <h3 className='text-md font-bold tracking-[-.15px]'>Crop Image</h3>
            <div className='bg-gray-100 px-2 py-2 rounded-md hover:bg-gray-200 transition-all cursor-pointer' onClick={()=>setActive(false)}>
              <img src={CloseIcon} className='w-5 h-5'/>
            </div>
        </div>
        <div className='flex px-8 py-4 pt-4 w-full '>
            <div className='overflow-hidden rounded-md'>
            <Cropper
            stencilProps={{aspectRatio:1/1,movable:true,resizable:true}}
                      ref={cropperRef}
                      style={{ height: 400, width: 400 }}
                      zoomTo={-0.1}
                      initialAspectRatio={0.5}
                      //   preview=".img-preview"
                      src={image}
                      viewMode={1}
                      minCropBoxHeight={40}
                      minCropBoxWidth={40}
                      background={false}
                      responsive={true}
                      aspectRatio={1}
                      autoCropArea={1}
                      checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
                      guides={true}
                      dragMode='move'
                      modal={true}
                      
                      />
                      </div>
        </div>
        <div className='flex mt-4 px-8 py-4 pt-4'>
            <button className='btn-md' onClick={getCroppedData}>Crop</button>
            <button className='ml-4 btn-md-disabled' onClick={()=>setActive(false)}>Cancel</button>
        </div>
    </div>
</div>
  )
}
