export const SETTINGS_LOAN_PROVIDERS_LIST = 'loanProviderTypes'
export const SETTINGS_APPROVAL_TIME_LIST = 'approvalTimes'
export const SETTINGS_LTV_LIST = 'ltvs'
export const SETTINGS_PRODUCT_TYPE_LIST = 'productTypes'
export const SETTINGS_PRODUCT_SCHEME_LIST = 'productSchemes'
export const SETTINGS_MOTOR_TYPE_LIST = 'productMotorTypes'
export const SETTINGS_PRODUCT_CATEGORIES_LIST = 'productCategories'
export const SETTINGS_PRODUCT_USAGES_LIST = 'productUsages'


export const DISTRIBUTOR_IMAGE_BASE_URL = 'http://kajah.localhost/uploads/distributor'
export const RETAILER_IMAGE_BASE_URL = 'http://kajah.localhost/uploads/retailer'
// export const DISTRIBUTOR_IMAGE_BASE_URL = 'http://kajah.localhost/uploads/distributor'
// export const DISTRIBUTOR_IMAGE_BASE_URL = 'http://kajah.localhost/uploads/distributor'
export const SORT_KEY_RECENT = 'created_at'
export const SORT_KEY_NAME = 'name'

export const SORT_ORDER_ASC = 'asc'
export const SORT_ORDER_DESC = 'desc'
export const LIMIT = 25

export const AGENT_RULE_ALL = 'abfd2554-c7b0-435f-94af-94b31492a2a6'
