
export default function StepThree ({submitting,products,manageData,setManageData,setStep,onSubmit,reps,retailers,distributors})
{

    const distributor = distributors.find(item=>item.id === manageData.orderDistributorId)
    const retailer = retailers.find(item=>item.id === manageData.orderRetailerId)
    const rep = reps.find(item=>item.id === manageData.orderSalesRepId)



    const getTotalOfferAmount = (type,value,productPrice,qty)=>
    {
      let orderOfferAmount = 0;
      console.log(type,value,productPrice,qty)
      if (type === 'amount') {
        // If the item offer type is 'percentage'
        let offerAmount = value ? value : 0; // Convert the percentage to a float
        let totalPrice = (qty * productPrice); // Convert the product price to a float
        orderOfferAmount = offerAmount; // Calculate the offer amount
    } else {
          let percentage =  value ? value : 0; // Convert the percentage to a float
          let totalPrice = (qty * productPrice); // Convert the product price to a float
          orderOfferAmount = (percentage / 100) * totalPrice; // Calculate the offer amount
        // If the item offer type is not 'percentage' (e.g., it could be a fixed value or something else)
        // Set offerAmount to 0 or handle it based on your specific requirements
      }
      return orderOfferAmount;
    }

    return <form onSubmit={(e)=>onSubmit(e)} id={'createOrder'} className='w-full h-[400px] overflow-y-scroll'>
        <div className="grid grid-cols-2">
            <div className="mb-4">
                <label>Distributor</label>
                <p className="text-[13px] font-medium">{distributor?.distributorCompanyName}</p>
            </div>
            <div className="mb-4">
                <label>Retailer</label>
                <p className="text-[13px] font-medium">{retailer?.retailerShopName}</p>
            </div>
            <div className="mb-4">
                <label>Sales Rep</label>
                <p className="text-[13px] font-medium">{rep?.firstName}</p>
            </div>
        </div>
        <table>
            <tbody>
    {
        products.map((item,index)=>
        {
            if(item.orderQty && item.orderQty > 0) return <tr key={index} className="px-0 mx-0 w-[100%]">
                <td className="px-0 py-2">
                    <div className="w-[40px] h-[40px]">
                    <img src={item.productImage} className="w-[40px] h-[40px] rounded-full"/>
                    </div>
                </td>
                <td className="px-0 pr-4 py-2 block">
                    <p className="text-[13px] font-medium tracking-tight">{item.productName}</p>
                    <p className="text-[11px] text-[#959595] font-regular">{item.productSku}</p>
                </td>
                <td className="px-4 py-2 block">
                    <p className="text-[13px] font-medium tracking-tight"><span className="text-xs text-[#959595]">Rs.</span>{item.productPrice*parseInt(item.orderQty)}</p>
                    <p className="text-[11px] text-[#959595] font-regular">Qty.{item.orderQty}</p>
                </td>
                <td className="px-4 py-2 block">
                    <p className="text-[13px] font-medium tracking-tight text-red-500"><span className="text-xs">Rs.</span>-{getTotalOfferAmount(item.itemOfferType,item.itemOfferValue,item.productPrice,item.orderQty) ? getTotalOfferAmount(item.itemOfferType,item.itemOfferValue,item.productPrice,item.orderQty) : 0}</p>
                    <p className="text-[11px] text-green-600 font-regular">Qty:(+{item.itemFreeItem ? item.itemFreeItem : 0})</p>
                </td>
                <td className="px-4 py-2 block">
                    <p className="text-[13px] font-medium tracking-tight"><span className="text-xs text-[#959595]">Rs.</span>{(item.productPrice*item.orderQty)-getTotalOfferAmount(item.itemOfferType,item.itemOfferValue,item.productPrice,item.orderQty)}</p>
                    <p className="text-[11px] text-[#959595] font-regular">Qty.{parseInt(item.orderQty)+parseInt(item.itemFreeItem ? item.itemFreeItem : 0)}</p>
                </td>
                <td className="px-4 py-2 block">
                    <p className="text-[13px] font-medium tracking-tight text-red-500"><span className="text-xs text-red-500">Rs.</span>{(item.productPrice*item.returnQty)}</p>
                    <p className="text-[11px] text-red-500 font-regular">Qty.{item.returnQty ? parseInt(item.returnQty) : 0}</p>
                </td>
        </tr>
        })
    }
    </tbody>
    </table>
</form>
}