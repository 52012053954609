
export default function TabGroup({options,activeTab='orders',setActiveTab}) {
    return (
        <div class={`relative self-stretch h-[full]`}>
            <div className='flex justify-start content-stretch'>
                {
                    options.map((item,index)=>
                    {
                        return <div key={index} onClick={()=>setActiveTab(item.value)} className={`cursor-pointer  transition-all duration-100 px-6 py-3 relative ${activeTab === item.value ? ' tab-group-active' : ''}`}>
                            <p className={`text-[13px] tracking-[-.25px] font-medium  ${activeTab === item.value ? 'text-[#2ac08b]' : 'text-[#757575]'}`}>{item.label}</p>
                        </div>
                    })
                }
            </div>
        </div>
  )
}