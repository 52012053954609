import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import Popup from '../../components/Popup'
import Input from '../../components/Input'
import FilterSelect from '../../components/FilterSelect'
import Select from '../../components/Select'
import Map from '../../components/Map'
import { useParams } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import ManageDistributor from './components/ManageDistributor'
import { ErrorToast, InfoToast } from '../../helpers/toasters'
import DistributorOrder from './components/DistributorOrder'

export default function DistributorInfo() {

    const {id} = useParams();
    const [distributorInfo,setDistributorInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [orders,setOrders] = useState([])
    const [activeTab,setActiveTab] = useState('orders')
    const [stocks,setStocks] = useState([])

    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowEdit(true)}>Edit Distributor</button>
        </div>
    }

    async function getDistributors(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/distributor/${id}?populate=true`)
            if(res.data.data) setDistributorInfo(res.data.data)
            let Ordersres = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/orders?distributorId=${id}&populate=true`)
            if(Ordersres.data.data) setOrders(Ordersres.data.data)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getDistributors();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/distributor/${id}`,data) 
            if(res.data.data)
            {
                await getDistributors()
                setShowEdit(false)
            }
            else toast('error updating distributor')
        } catch (error) {
            toast(error.response.data)
        }
    }

  return (
    <>
        <Header  parentLink='distributors' parentTitle='Distributors' title={distributorInfo.distributorCompanyName} RightContent={RightContent}/>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
            <div>
               <TabGroup activeTab={activeTab} setActiveTab={setActiveTab} options={[{label:'Orders',value:'orders'},{label:'Stocks',value:'stocks'},{label:'Margins',value:'margins'},{label:'Profile',value:'profile'}]}/>
            </div>
        </div>
        <div className='flex flex-1 overflow-scroll bg-white w-full'>
            {activeTab === 'orders' ? <DistributorOrder id={id} orders={orders}/> : activeTab === 'stocks' ? <StockInfo id={id}/> : activeTab === 'margins' ? <MarginInfo id={id}/> : <ProfileInfo data={distributorInfo}/>}
            
        </div>
        { showEdit ? <ManageDistributor onClose={setShowEdit} onSubmit={onSubmit} update={true} updateData={distributorInfo}/> : null}
    </>
  )
}


const StockInfo = ({id})=>
{
    const [searchText,setSearchText] = useState('')
    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const [stocks,setStocks] = useState([])
    const [products,setProducts] = useState([])
    const [showUpdate,setShowUpdate] = useState(false)
    const [showReturnUpdate,setShowReturnUpdate] = useState(false)

    async function getStocks(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/stock/${id}?populate=true`)
            let productres = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/product/${id}?populate=true`)
            if(res.data.data) setStocks(res.data.data)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getStocks();
    },[])
    
    
    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let productres = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/stock`,{stockDistributorId:id,stockProductId:data.productId,stockQty:data.stockCount})
            await getStocks();
            setShowUpdate(null)
            InfoToast('Stock Updated')
        } catch (error) {
            ErrorToast('Error getting products')
        }
    }

    const onReturnSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let productres = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/stock/clear-return`,{stockDistributorId:id,stockProductId:data.productId})
            await getStocks();
            setShowReturnUpdate(null)
            InfoToast('Return stock cleared')
        } catch (error) {
            ErrorToast('Error clearing return products')
        }
    }

    return <><div className='flex justify-start h-full w-full self-stretch'>
        
<div className='flex flex-1 bg-white w-full overflow-scroll'>
<table className='table-auto w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Image</p></td>
                    <td><p>Name/SKU</p></td>
                    {/* <td><p>Category</p></td> */}
                    <td><p>Weight(In Gms)</p></td>
                    <td><p>Stock</p></td>
                    <td><p>Return Stock</p></td>
                    <td><p>Total Value</p></td>
                    <td><p>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        stocks.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <img src={item.productImage} className='w-16 h-16 rounded-full'/>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productName}</p>
                                        <p className='text-xs my-0 text-gray-400'>{item.productSku}</p>
                                    </div>
                                </td>
    
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productWeight}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{parseInt(item.stockCount)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{parseInt(item.returnQty)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>Rs.{(parseInt(item.stockCount)*item.productPrice)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs mb-1' onClick={()=>setShowUpdate(item)}>Update</button>
                                    <button className='btn-xs' onClick={()=>setShowReturnUpdate(item)}>Clear Return</button>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
    </div>
    </div>
    {showUpdate ? <ManageStock data={showUpdate} onSubmit={onSubmit} onClose={()=>setShowUpdate(null)}/> : null}
    {showReturnUpdate ? <ManageReturnQty data={showReturnUpdate} onSubmit={onReturnSubmit} onClose={()=>setShowReturnUpdate(null)}/> : null}
    </>
}


const MarginInfo = ({id})=>
{

    const [margins,setMargins] = useState([])
    const [showUpdate,setShowUpdate] = useState(false)

    async function getMargins(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/margin/${id}?populate=true`)
            let productres = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/product/${id}?populate=true`)
            if(res.data.data) setMargins(res.data.data)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getMargins();
    },[])
    
    
    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let productres = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/margin`,{marginDistributorId:id,marginProductId:data.productId,marginAmount:data.marginAmount})
            await getMargins();
            setShowUpdate(null)
            InfoToast('Stock Updated')
        } catch (error) {
            ErrorToast('Error getting products')
        }
    }


    return <><div className='flex justify-start h-full w-full self-stretch'>
        
<div className='flex flex-1 bg-white w-full overflow-scroll'>
<table className='table-auto w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Image</p></td>
                    <td><p>Name/SKU</p></td>
                    <td><p>Weight(In Gms)</p></td>
                    <td><p>Resale Amount</p></td>
                    <td><p>Margin Amount</p></td>
                    <td><p>Total Amount</p></td>
                    <td><p>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        margins.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <img src={item.productImage} className='w-16 h-16 rounded-full'/>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productName}</p>
                                        <p className='text-xs my-0 text-gray-400'>{item.productSku}</p>
                                    </div>
                                </td>
    
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.productWeight}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{parseFloat(item.productPrice)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{parseFloat(item.marginAmount)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>Rs.{(parseFloat(item.productPrice)+parseFloat(item.marginAmount))}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs mb-1' onClick={()=>setShowUpdate(item)}>Update</button>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
    </div>
    </div>
    {showUpdate ? <ManageMargin data={showUpdate} onSubmit={onSubmit} onClose={()=>setShowUpdate(null)}/> : null}
    </>
}

const ManageMargin = ({onClose,onSubmit,data})=>
{
    console.log('item',data)
    const [manageData,setManageData] = useState({marginAmount:data.marginAmount,productId:data.id})
    
    return <Popup title={'Update Margin'} submitTitle={'Update'} onClose={onClose} formName={'updateMargin'}>
        <form onSubmit={(e)=>onSubmit(e,manageData)} id={'updateMargin'} className='grid grid-cols-2 gap-x-3 gap-y-6 w-full'>
            <div>
                <label>Product Name</label>
                <p className='text-sm text-medium'>{data?.productName}</p>
            </div>
            <div>
                <label>Product SKU</label>
                <p className='text-sm text-medium'>{data?.productSku}</p>
            </div>
            <div className='col-span-2'>
                <label>Product Margin Amount</label>
                <Input type='text' number={false} placeholder={'Enter Margin Amount'} value={manageData.marginAmount ? parseFloat(manageData.marginAmount) : ''} setValue={(value)=>setManageData(data=>({...data,marginAmount:value}))}/>
            </div>
        </form>
    </Popup>
}

const ManageStock = ({onClose,onSubmit,data})=>
{
    console.log('item',data)
    const [manageData,setManageData] = useState({stockCount:data.stockCount,productId:data.id})
    
    return <Popup title={'Update Stock'} submitTitle={'Update'} onClose={onClose} formName={'updateStock'}>
        <form onSubmit={(e)=>onSubmit(e,manageData)} id={'updateStock'} className='grid grid-cols-2 gap-x-3 gap-y-6 w-full'>
            <div>
                <label>Product Name</label>
                <p className='text-sm text-medium'>{data?.productName}</p>
            </div>
            <div>
                <label>Product SKU</label>
                <p className='text-sm text-medium'>{data?.productSku}</p>
            </div>
            <div className='col-span-2'>
                <label>Product Stock</label>
                <Input type='text' placeholder={'Enter Stock'} value={manageData.stockCount ? parseInt(manageData.stockCount) : ''} setValue={(value)=>setManageData(data=>({...data,stockCount:value}))}/>
            </div>
        </form>
    </Popup>
}

const ManageReturnQty = ({onClose,onSubmit,data})=>
{
    console.log('item',data)
    const [manageData,setManageData] = useState({productId:data.id})
    
    return <Popup title={'Clear Return Stock'} submitTitle={'Clear Return Stock'} onClose={onClose} formName={'clearReturnStock'}>
        <form onSubmit={(e)=>onSubmit(e,manageData)} id={'clearReturnStock'} className='grid grid-cols-3 gap-x-3 gap-y-6 w-full'>
            <div>
                <label>Product Name</label>
                <p className='text-sm text-medium'>{data?.productName}</p>
            </div>
            <div>
                <label>Product SKU</label>
                <p className='text-sm text-medium'>{data?.productSku}</p>
            </div>
            <div>
                <label>Return Stock</label>
                <p className='text-sm text-medium'>Qty.{data?.returnQty}</p>
            </div>
        </form>
    </Popup>
}
const ProfileInfo = ({data})=>
{
    // const [info,setInfo] = useState({})
    const [cities,setCities] = useState('')
    const [routes,setRoutes] = useState('')

    useEffect(()=>
    {
        if(data.distributorRoutes)
        {
            let cityNames = [];
            let routeNames = [];
            
            data.distributorRoutes.forEach(item => {
                // Extract city name
                const cityName = item.cityInfo.cityName;
                cityNames.push(cityName);
                
                // Extract route names
                item.routes.forEach(route => {
                    const routeName = route.routeInfo.routeName;
                    routeNames.push(routeName);
                });
            });
            
            setCities(cityNames.join(', '));
            setRoutes(routeNames.join(', '));
            
        }
    },[data])

    return <div className='flex justify-start items-start h-[100%] py-12 px-6'>
        {/* <div className='mb-4 w-[120px] h-[120px] mr-20'>
            <img src={info?.retailerImage} className='w-[120px] h-[120px] rounded-full'/>
        </div> */}
        <div className='grid grid-cols-4 gap-6'>
            <div>
                <label>Company Name</label>
                <p className='text-sm'>{data?.distributorCompanyName}</p>
            </div>
            <div>
                <label>Owner Name</label>
                <p className='text-sm'>{data?.distributorName}</p>
            </div>
            <div>
                <label>Contact Number</label>
                <p className='text-sm'>{data?.distributorContactNumber}</p>
            </div>
            <div>
                <label>Email Address</label>
                <p className='text-sm'>{data?.distributorEmail}</p>
            </div>
            <div>
                <label>Cities</label>
                <p className='text-sm'>{cities}</p>
            </div>
            <div>
                <label>Routes</label>
                <p className='text-sm'>{routes}</p>
            </div>
            <div>
                <label>Area</label>
                <p className='text-sm'>{data?.distributorArea}</p>
            </div>
            <div>
                <label>Address</label>
                <p className='text-sm'>{data?.distributorAddress}</p>
            </div>
            {data.distributorLat ? <div>
                <label>Map Location</label>
                <a href={`https://maps.google.com/?q=${data.distriutorLat},${data.distriutorLong}`} className='text-sm text-blue-600 underline' target='_blank'>Map Link</a>
            </div> : null}
        </div>
    </div>
}
