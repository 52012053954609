import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import { authAxios } from "../../../helpers/axios"
import SlidePopup from "../../../components/SlidePopup"
import FilterSelect from "../../../components/FilterSelect"

export default function SalesOrderFilter({filters,onSubmit,onClose,clearFilter})
{
    const [localFilters,setLocalFilters] = useState({routeId:null,cityId:null,status:null,distributorId:null,retailerId:null,startDate:null,endDate:null})
    const [cities,setCities] = useState([])
    const [retailers,setRetailers] = useState([])
    const [routes,setRoutes] = useState([])
    const [reps,setReps] = useState([])
    const [distributors,setDistributors] = useState([])
    const [loading,setLoading] = useState(true)


    useEffect(()=>
    {
        console.log('local filters',localFilters)
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCities(res.data.data)
            setLoading(false)
            setLocalFilters(filters)
        }
        getCities()
        setLocalFilters(filters)
    },[])

    async function getRoutes(){
        let query =''
        if(localFilters.cityId) query+=`routeCity=${localFilters.cityId.id}`
        let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?${query}`)
        if(res.data.data)  setRoutes(res.data.data)
    }
    useEffect(()=>
    {
        if(localFilters.cityId !== '') getRoutes()
        else {
            setLocalFilters(data=>({...data,routeId:''}))
        }
    },[localFilters.cityId])


    
    useEffect(()=>
    {
        let query =''
        if(localFilters.cityId) query+=`repCity=${localFilters.cityId.id}`
        async function getRoutes(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?${query}`)
            setRoutes(res.data.data)
        }
        async function getSalesRep(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/salesrepresentative?${query}`)
            setReps(res.data.data)
        }
        if(localFilters.cityId !== '') getRoutes()
        if(localFilters.cityId !== '') getSalesRep()
    },[localFilters.cityId])

    useEffect(()=>
    {
        let query =''
        let disQuery = ''
        if(localFilters.cityId) 
        {
            query+=`retailerCity=${localFilters.cityId.id}`
            disQuery+=`distributorCity=${localFilters.cityId.id}`
        }
        if(localFilters.routeId)
        {
            if(localFilters.cityId) 
            {
                query+=`&retailerRoute=${localFilters.routeId.id}`
                disQuery+=`&distributorRoute=${localFilters.routeId.id}`
            }
            else 
            {
                query+=`&retailerRoute=${localFilters.routeId.id}`
                disQuery+=`&distributorRoute=${localFilters.routeId.id}`
            }
        }
        async function getRetailers(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/retailer?${query}`)
            setRetailers(res.data.data)
        }
        async function getDistributors(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/distributor?${disQuery}`)
            setDistributors(res.data.data)
        }
        if(localFilters.cityId !== '') getRetailers()
        if(localFilters.cityId !== '') getDistributors()
    },[localFilters.routeId])


    
    
    return <><SlidePopup title={'Order Filters'} formName={'createOrder'} onClose={onClose}>
        {
            loading ?  <p>Loading</p>: 
            <div className="w-full">
            <div className="grid grid-cols-2 gap-x-6 gap-y-6">
                <div>
                    <label>City</label>
                    <div className='h-[100%]'>
                    <FilterSelect placeholder={'Select city'} options={cities} value={localFilters.cityId} customLabel={'cityName'} customValue={'id'} setValue={(value)=>setLocalFilters(data=>({...data,cityId:value}))} padding={false}/>
                </div>
                </div>
                <div>
                    <label>Route</label>
                    <div className='h-[100%]'>
                    <FilterSelect placeholder={'Select route'} options={routes} value={localFilters.routeId} customLabel={'routeName'} customValue={'id'} setValue={(value)=>setLocalFilters(data=>({...data,routeId:value}))} padding={false}/>
                </div>
                </div>
                <div>
                    <label>Distributor</label>
                    <div className='h-[100%]'>
                    <FilterSelect placeholder={'Select Distributor'} options={distributors} value={localFilters.distributorId} customLabel={'distributorCompanyName'} customValue={'id'} setValue={(value)=>setLocalFilters(data=>({...data,distributorId:value}))} padding={false}/>
                </div>
                </div>
                <div>
                    <label>Retailer</label>
                    <div className='h-[100%]'>
                    <FilterSelect placeholder={'Select Retailer'} options={retailers} value={localFilters.retailerId} customLabel={'retailerShopName'} customValue={'id'} setValue={(value)=>setLocalFilters(data=>({...data,retailerId:value}))} padding={false}/>
                </div>
                </div>
                <div>
                    <label>Status</label>
                    <div className='h-[100%]'>
                    <select placeholder="Select Status" defaultValue={localFilters.status} className={`min-w-[120px] px-2 pr-0`} value={localFilters.status} onChange={(e)=>setLocalFilters(data=>({...data,status:e.target.value}))}>
                        <option value=''>All</option>
                        <option value={1}>Pending</option>
                        <option value={2}>Accepted</option>
                        <option value={4}>Cancelled</option>
                    </select>
                </div>
                </div>
                <div>
                    <label>Start Date</label>
                    <div className='h-[100%]'>
                    <Input type='date' placeholder={'Select Start Date'} value={localFilters.startDate} setValue={(value)=>setLocalFilters(data=>({...data,startDate:value}))}/>
                </div>
                </div>
                <div>
                    <label>End Date</label>
                    <div className='h-[100%]'>
                    <Input type='date' placeholder={'Select End Date'} value={localFilters.endDate} setValue={(value)=>setLocalFilters(data=>({...data,endDate:value}))}/>
                </div>
                </div>
                <div className='flex justify-end mt-4  w-full col-span-2'>
                    <button type='button' className='btn-md-disabled' onClick={clearFilter}>Clear Filters</button>
                    <button type='submit' className='ml-4 btn-md' onClick={()=>onSubmit(localFilters)}>Filter Orders</button>
                </div>
            </div>
        </div>
        }
    </SlidePopup>
    </>
}



