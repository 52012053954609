import React, { useEffect, useState } from 'react'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import { ErrorToast, InfoToast } from '../../../helpers/toasters'
import Input from '../../../components/Input'
import Popup from '../../../components/Popup'
import SmallPopup from '../../../components/SmallPopup'

export default function Categories({setShowManage,showManage}) {

    const [categories,setCategories] = useState([])
    const [showDeleteCategory,setShowDeleteCategory] = useState(false)
    const [deleting,setDeleting] = useState(false)

    async function getCategories(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/category`)
            if(res.data.data)
            {
                setCategories(res.data.data)
            }
        } catch (error) {
            console.log(error)
            toast('Error getting orders')
        }
    } 

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res;
            console.log('sbm',data)
            if(showManage.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/category/${data.id}`,{...data})  
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/category`,{...data}) 
                
            }
            if(res.data.data)
            {
                if(showManage.edit)
                {
                    setCategories(cities=>{
                        let prev = [...cities]
                        let itemIndex = prev.findIndex(item=>item.id === showManage.edit)
                        console.log('itemIndex',itemIndex,'res',res.data.data.data)
                        prev[itemIndex] = {...prev[itemIndex],categoryName:res.data.data.data.categoryName}
                        return prev;
                    })
                }
                else setCategories(cities=>([...cities,{...res.data.data.data}]))
                InfoToast(showManage.edit ? 'Category Updated' : 'Category Created',{position: toast.POSITION.BOTTOM_CENTER,hideProgressBar:true})
                setShowManage({type:null,status:false,edit:null})
            }
            else toast('error creating category')
        } catch (error) {
            console.log(error)
            ErrorToast(error.response.data.error.categoryName)
        }
    }

    const onCategoryDelete = async(e)=>
    {
        try 
        {
            setDeleting(true)
            await authAxios.delete(`${process.env.REACT_APP_BASE_URL}/category/${showDeleteCategory}`) 
            getCategories()
            setShowDeleteCategory(false)
            setDeleting(false)
            InfoToast('Category Deleted')
        } catch (error) {
            setDeleting(false)
            ErrorToast('Category Deletion Failed')
            
        }
    }


    useEffect(()=>
    {
        getCategories();
    },[])


  return (
    <div className='flex flex-col w-full'>
        <div className='bg-white w-full flex-1 flex overflow-scroll'>
            <table className=' table-auto w-full flex-1 '>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Category Name</p></td>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        categories.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.categoryName}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs' onClick={()=>setShowManage({type:'categories',status:true,edit:item.id})}>Edit</button>
                                    <button className='btn-xs btn-red ml-1' onClick={()=>setShowDeleteCategory(item.id)}>Delete</button>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showManage.status && showManage.type ==='categories' ? <ManageCategory setShow={setShowManage} onSubmit={onSubmit} edit={showManage.edit} /> : null}
        {showDeleteCategory ? <DeleteHsn submitting={deleting} onSubmit={onCategoryDelete} onClose={setShowDeleteCategory}/> : null}
    </div>
  )
}

const DeleteHsn = ({onSubmit,onClose,submitting})=>
{
    return <SmallPopup submitting={submitting} title={'Delete Category'} onClose={onClose} onSubmit={onSubmit} submitTitle={'Yes, Delete'}>
        <div>
            <p className="text-sm">Are you sure you want to delete the Category?</p>
        </div>
    </SmallPopup>
}


const ManageCategory = ({setShow,onSubmit,edit=false})=>
{
    const [category,setCategory] = useState({id:null,categoryName:''})
    const [loading,setLoading] = useState(edit ? true : false)
    useEffect(()=>
    {
        async function getCategoryInfo(){
            if(edit)
            {
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/category/${edit}`)
                console.log(res.data.data)
                setCategory({id:res.data.data.id,categoryName:res.data.data.categoryName})
                setLoading(false)
            }
        }
        getCategoryInfo()
    },[])
    return loading ? 'loading' :<Popup onClose={()=>setShow({type:null,status:false})}  title={edit ?  'Edit Category' : 'Add Category'} submitTitle={edit ? 'Update' : 'Add'} formName={'createCategory'}>
        <form className='w-full' onSubmit={(e)=>onSubmit(e,edit ? category : {categoryName:category.categoryName})} id="createCategory">
            <div>
                <label>Category Name</label>
                <Input placeholder={'Enter Category Name'} value={category.categoryName} setValue={(value)=>setCategory(category=>({...category,categoryName:value}))} required={true}/>
            </div>
        </form>
    </Popup>
}
