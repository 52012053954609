import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import ManageRep from './components/ManageRep'
import SalesOrder from './components/SalesOrder'
import SalesRoute from './components/SalesRoute'

export default function SalesRepInfo() {

    const {id} = useParams();
    // const id = 'd48a0ac8-0aac-ce9f-2f43-b0823a8458d1'
    const [repInfo,setRepInfo] = useState([])
    const [orders,setOrders] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [activeTab,setActiveTab] = useState('orders')
    const [submitting,setSubmitting] = useState(false)

    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowEdit(true)}>Edit Sales Rep</button>
        </div>
    }

    async function getSalesRep(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/salesrepresentative/${id}?populate=true`)
            if(res.data.data) setRepInfo(res.data.data)
            let orderRes = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/orders?salesRepId=${id}&populate=true`)
            if(orderRes.data.data) setOrders(orderRes.data.data)
        } catch (error) {
            toast('Error getting distributors')
        }
    }
    useEffect(()=>
    {
        getSalesRep();
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            console.log('checking',!data.cities || data.cities.length <= 0 || data.cities.some(route => route.routes && route.routes.length <= 0))
            if(!data.cities || data.cities.length <= 0 || data.cities.some(route => route.routes && route.routes.length <= 0))
            {
              toast.error('Select Atleast One Route')
              return true;
            }
            else
            {
                setSubmitting(true)
                let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/salesrepresentative/${id}?populate=true`,data) 
                if(res.data.data)
                {
                    await getSalesRep()
                }
                else toast('error updating retailer')
                setShowEdit(false)
                setSubmitting(false)
            }
        } catch (error) {
            setSubmitting(false)
            toast(error.response.data)
        }
    }

  return (
    <>
        <Header title={repInfo.firstName} RightContent={RightContent} parentTitle='Sales Rep' parentLink='sales-representatives'/>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
            <div>
               <TabGroup setActiveTab={setActiveTab} activeTab={activeTab} options={[{label:'Orders',value:'orders'},{label:'Route Schedule',value:'route-schedule'},{label:'Profile',value:'profile'}]}/>
            </div>
        </div>
        <div className='bg-white w-full flex-1 flex overflow-scroll'>
            {activeTab === 'orders' ? <SalesOrder id={id} orders={orders}/> : activeTab === 'route-schedule' ? <SalesRoute id={id}/> : <ProfileInfo id={id}/>}
            
        </div>
        { showEdit ? <ManageRep submitting={submitting} onClose={setShowEdit} onSubmit={onSubmit} edit={id} updateData={repInfo}/> : null}
    </>
  )
}




const ProfileInfo = ({id})=>
{
    const [info,setInfo] = useState({})
    const [cities,setCities] = useState('')
    const [routes,setRoutes] = useState('')

    useEffect(()=>
    {
        if(info.cities)
        {
            let cityNames = [];
            let routeNames = [];
            
            info.cities.forEach(item => {
                // Extract city name
                const cityName = item.cityInfo.cityName;
                cityNames.push(cityName);
                
                // Extract route names
                item.routes.forEach(route => {
                    const routeName = route.routeInfo.routeName;
                    routeNames.push(routeName);
                });
            });
            
            setCities(cityNames.join(', '));
            setRoutes(routeNames.join(', '));
            
        }
    },[info])



    // let routes = data.flatMap(item => item.routeIds.map(routeId => {
    //     const routeInfo = routeId.routeName; // Adjust this based on your route information
    //     return routeInfo;
    // }));

    useEffect(()=>
    {
        async function getSalesRepInfo(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/salesrepresentative/${id}?populate=true`)
            setInfo(res.data.data)
        }
        getSalesRepInfo()
    },[])
    return <div className='flex justify-start items-start h-[100%] w-full self-stretch py-12 px-6'>
        {/* <div className='mb-4 w-[120px] h-[120px] mr-20'>
            <img src={info?.retailerImage} className='w-[120px] h-[120px] rounded-full'/>
        </div> */}
        <div className='grid grid-cols-4 gap-6'>
            <div>
                <label>Full Name</label>
                <p className='text-sm'>{info?.firstName}</p>
            </div>
            <div>
                <label>Email</label>
                <p className='text-sm'>{info?.repEmail}</p>
            </div>
            <div>
                <label>Contact Number</label>
                <p className='text-sm'>{info?.repContactNumber}</p>
            </div>
            <div>
                <label>City</label>
                <p className='text-sm'>{cities}</p>
            </div>
            <div>
                <label>Routes</label>
                <p className='text-sm'>{routes}</p>
            </div>
            <div>
                <label>Team</label>
                <p className='text-sm'>{info?.repTeam?.teamName}</p>
            </div>
        </div>
    </div>
}
