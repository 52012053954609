import { useEffect, useState } from "react"
import { authAxios } from "../../../helpers/axios"
import Select from "../../../components/Select"

export default function StepOne({manageData,setManageData,setStep,routes,distributors,retailers,cities,selectedCity,setSelectedCity,selectedRoute,setSelectedRoute,reps,onSubmit})
{

    return <form onSubmit={(e)=>onSubmit(e,2)} id={'createOrder'} className='grid grid-cols-2 gap-x-12 gap-y-0 w-full h-[400px] overflow-x-scroll content-start'>
    <div>
        <label>City</label>
        <Select placeholder={'Select City'} customLabel={'cityName'} options={cities}  required={true} customValue={'id'} value={selectedCity} setValue={(value)=>setSelectedCity(value)}/>
    </div>
    <div>
        <label>Routes</label>
        <Select placeholder={'Select Route'} customLabel={'routeName'} required={true} options={routes} customValue={'id'} value={selectedRoute} setValue={(value)=>setSelectedRoute(value)}/>
    </div>
    <div>
        <label>Retailer</label>
        <Select placeholder={'Select Retailer'} required={true} customLabel={'retailerShopName'} options={retailers} customValue={'id'} value={manageData.orderRetailerId} setValue={(value)=>setManageData(data=>({...data,orderRetailerId:value}))}/>
    </div>
    <div>
        <label>Distributor</label>
        <Select placeholder={'Select Distributor'} required={true} customLabel={'distributorCompanyName'} options={distributors} customValue={'id'} value={manageData.orderDistributorId} setValue={(value)=>setManageData(data=>({...data,orderDistributorId:value}))}/>
    </div>
    <div>
        <label>Sales Representative</label>
        <Select placeholder={'Select Sales Rep'} required={true} customLabel={'firstName'} options={reps} customValue={'id'} value={manageData.orderSalesRepId} setValue={(value)=>setManageData(data=>({...data,orderSalesRepId:value}))}/>
    </div>
</form>
}