import React, { useEffect, useState } from 'react'
// import Header from '../../components/Header'
import SearchInput from '../../../components/SearchInput'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import TargetUI from '../../../components/TargetUI'
import { Tooltip } from 'react-tooltip'
// import ManageRep from '../components/ManageRep'

export default function TeamLeaderboard({month}) {

    const [searchText,setSearchText] = useState('')
    const [reps,setReps] = useState([])
    const [showManage,setShowManage] = useState({status:false,edit:null})

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowManage({status:true,edit:null})}>Add Retailer</button>
        </div>
    }

    function convertMonthYearFormat(date) {
        const month = date.getMonth() + 1; // Adding 1 because getMonth() returns 0-11
        const year = date.getFullYear();
        
        const monthString = month < 10 ? `0${month}` : `${month}`;
        
        return `${monthString}-${year}`;
}

    async function getReps(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/leaderboardteam?populate=true&month_and_year=${convertMonthYearFormat(month)}`)
            if(res.data.data) setReps(res.data.data)
        } catch (error) {
            toast('Error getting retailer')
        }
    }
    useEffect(()=>
    {
        getReps();
    },[month])

    useEffect(()=>
    {
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCityFilters(res.data.data)
        }
        getCities()
    },[])

    useEffect(()=>
    {
        async function getRoutes(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?routeCity=${selectedFilters.city}`)
            if(res.data.data)  setRouteFilters(res.data.data)
        }
        if(selectedFilters.city !== '') getRoutes()
        else {
            setRouteFilters([]);
            setSelectedFilters(data=>({...data,route:''}))
        }
    },[selectedFilters.city])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            console.log(data)
            // let base64Image = await getBase64Image(data.retailerShopImage)
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/salesrepresentative`,{...data}) 
            if(res.data.data)
            {
                await getReps()
                toast.info('Sales Rep Added')
                setShowManage({edit:null,status:false})
            }
            else toast('error updating retailer')
        } catch (error) {
            console.log(error)
            toast(error.response)
        }
    }


  return (
    <>
        {/* <Header title={'Sales Rep. Management'} RightContent={RightContent}/> */}
        {/* <div className='flex-auto flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
            <div>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Retailer'} label={'Search Retailer'} />
            </div>
            <div className='flex justify-end items-stretch h-[100%] self-stretch'>
                <div className='h-[100%]'>
                    <FilterSelect placeholder={'Select city'} options={cityFilters} value={selectedFilters.city} customLabel={'cityName'} customValue={'id'} setValue={(value)=>setSelectedFilters(data=>({...data,city:value}))} padding={false}/>
                </div>
            <div>
                <FilterSelect placeholder={'Select route'} options={routeFilters} value={selectedFilters.route} customLabel={'routeName'} customValue={'id'} setValue={(value)=>setSelectedFilters(data=>({...data,route:value}))} padding={false}/>
            </div>
            </div>
        </div> */}
        <div className='flex flex-1 bg-white w-full overflow-scroll'>
            <table className='table-auto w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Name</p></td>
                    {/* <td><p>Team Lead</p></td> */}
                    <td><p>City</p></td>
                    <td><p>Target</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        reps.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.teamId.teamName}</p>
                                    </div>
                                </td>
                                {/* <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.teamId?.teamRepId ? item.teamId?.teamRepId.firstName : 'Not Assigned'}</p>
                                    </div>
                                </td> */}
                                <td className='capitalize'>
                                <div>
                                {item.teamId.cities && item.teamId.cities.length >= 1 && item.teamId.cities[0].cityName ? <CityRouteInfo id={item.teamId.id} data={item.teamId.cities}/> : <p className='text-sm font-regular my-0'>Not Available</p>}
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{parseFloat(item.percentage).toFixed(2)}%</p>
                                        <TargetUI percentage={item.percentage ? parseInt(item.percentage) : 0}/>
                                        <p className='text-[13px] text-gray-400 my-0 lowercase'>{item.score ? item.score : 0} of {item.teamId.teamTarget ? item.teamId.teamTarget : 0} Pts</p>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        {/* { showManage.status ? <ManageRep onClose={setShowManage} onSubmit={onSubmit} edit={showManage.edit}/> : null} */}
    </>
  )
}


const CityRouteInfo = ({ data ,id}) => {



    return (
      <div>
        <p className='text-sm font-regular my-0 flex items-start' >{data.length > 0 ? data[0].cityName : 'Not Available'}{data.length > 1 ? <span data-tooltip-id={`${id}-city`} className='ml-1 text-[9px] font-semibold h-[16px] w-[16px] flex justify-center items-center text-center bg-black text-white rounded-lg'>{data.length > 1 ? ` +${data.length - 1}` : ''}
        </span> : null}
        </p>
  
          <Tooltip id={`${id}-city`} effect="solid" place="top" globalEventOff="click" clickable>
            {data.map((item, index) => (
              <p className='text-xs' key={index}>{item.cityName}</p>
            ))}
          </Tooltip>

      </div>
    );
  };
