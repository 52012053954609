import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/SearchInput'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ErrorToast, InfoToast } from '../../../helpers/toasters'
import { LIMIT } from '../../../helpers/constants'
import RightIcon from '../../../images/right.svg'
import SortIcon from '../../../images/sort.svg'
import FilterIcon from '../../../images/filter.svg'
import FilterActiveIcon from '../../../images/filteractive.svg'
import FilterRoute from '../components/RouteFilter'
import { getDateFormat, getTimeFormat } from '../../../helpers/utils'
import Select from '../../../components/Select'
import Input from '../../../components/Input'
import Popup from '../../../components/Popup'
import SmallPopup from '../../../components/SmallPopup'

export default function Routes({showManage,setShowManage}) {

    const [searchText,setSearchText] = useState('')
    const [routes,setRoutes] = useState([])
    // const [showManage,setShowManage] = useState({status:false,edit:null})
    const [vieworder,setVieworder] = useState(false)
    const [sort,setSort] = useState('name')

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const [summary,setSummary] = useState({})
    const [filters,setFilters] = useState({routeId:null,cityId:null,status:null,distributorId:null,retailerId:null,startDate:null,endDate:null})
    const [showFilters,setShowFilters] = useState(false)
    const [showDeleteRoute,setShowDeleteRoute] = useState(false)
    const [deleting,setDeleting] = useState(false)
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    // const RightContent = ()=>
    // {
    //     return <div>
    //       <button type='button' className='btn-md' onClick={()=>setShowManage({status:true,edit:null})}>Create Order</button>
    //     </div>
    // }

    async function getRoutes(){

        try 
        {
            console.log('filters',filters)
            let query = `limit=${LIMIT}&offset=${offset}&search=${searchText}&populate=true&orderBy=${sort}`;
            // query+=``
            if(filters.cityId) query+=`&routeCity=${filters.cityId.id}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?${query}`)
            console.log('salse',res.data.data)
            if(res.data.data)
            {
                setRoutes(res.data.data)
                setCount(res.data.count)
                setSummary(res.data.summary)
                // setOffset(offset+LIMIT)
            }
        } catch (error) {
            console.log(error)
            toast('Error getting orders')
        }
    } 

    const onFilterSubmit = (filters)=>
    {
        try 
        {
            setFilters({...filters})
            setCount(5)
            setOffset(0)
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const clearFilter = ()=>
    {
        try 
        {
            setFilters({routeId:null,cityId:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }



    useEffect(()=>
    {
        getRoutes();
    },[offset,searchText,filters,sort])

    useEffect(()=>
    {
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCityFilters(res.data.data)
        }
        getCities()
    },[])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            let res;
            e.preventDefault()
            if(showManage.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/route/${data.id}`,{...data})  
                // let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/route`,{...data}) 
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/route`,{...data}) 
                // res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/category`,{...data}) 
                
            }
            if(res.data.data)
            {
                if(showManage.edit)
                {
                    setRoutes((prev)=>
                    {
                        let newData = [...prev];
                        let index = newData.findIndex(item=>item.id === data.id)
                        newData[index] = res.data.data.data;
                        return newData;
                    })
                }
                else setRoutes(cities=>([...cities,{...res.data.data.data}]))
                setShowManage({type:null,status:false})
                InfoToast('Route Updated')
            }
            else ErrorToast('error creating/updating route')
        } catch (error) {
            console.log(error)
            ErrorToast(error.response.data.error.routeName)
        }
    }


    const onRouteDelete = async(e)=>
    {
        try 
        {
            setDeleting(true)
            await authAxios.delete(`${process.env.REACT_APP_BASE_URL}/route/${showDeleteRoute}`) 
            getRoutes()
            setShowDeleteRoute(false)
            setDeleting(false)
            InfoToast('Route Deleted')
        } catch (error) {
            console.log('err',error)
            setDeleting(false)
            ErrorToast('Route Deletion Failed')
            
        }
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }


  return (
    <div className='flex flex-col w-full'>
    <div>
          <div className=' flex h-full justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
            <div className='flex'>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search route'} label={'Search order'} />
                <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div
                        className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                        onClick={() => {
                        if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }
                        }}
                    >
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180' alt='Previous Page' />
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div
                        className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                        onClick={() => {
                        if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }
                        }}
                    >
                        <img src={RightIcon} className='w-[20px] h-[20px]' alt='Next Page' />
                    </div>
                    </div>
            </div>
            <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(filters.cityId || filters.routeId || filters.distributorId || filters.retailerId || filters.teamId || filters.salesRepId || filters.startDate || filters.endDate) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div>
        </div>
        </div>
        <div className='bg-white w-full flex-1 flex overflow-scroll'>
            <table className=' table-auto w-full flex-1 '>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>

                    <td><p onClick={()=>onSortPress('name')} className='cursor-pointer flex items-center hover:text-black'>Route Name {sort === 'name' || sort === '-name'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td><p onClick={()=>onSortPress('city')} className='cursor-pointer flex items-center hover:text-black'>Route City {sort === 'city' || sort === '-city'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td><p className='cursor-pointer flex items-center hover:text-black'>Route Description </p></td>

                    <td><p className='cursor-pointer flex items-center hover:text-black'>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        routes.map((item,index)=>
                        {
                            return <tr onClick={()=>setVieworder(item.id)}>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.routeName}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.routeCity.cityName}</p>
                                    </div>
                                </td>
                                <td className='capitalize w-full'>
                                <div>
                                  <p className='text-sm font-regular my-0 break-all'>{item.routeDescription}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs' onClick={()=>setShowManage({type:'routes',status:true,edit:item.id})}>Edit</button>
                                    <button className='btn-xs btn-red ml-1' onClick={()=>setShowDeleteRoute(item.id)}>Delete</button>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showManage.status && showManage.type === 'routes' ? <ManageRoute setShow={setShowManage} onSubmit={onSubmit} edit={showManage.edit}/> : null}
        { showFilters ? <FilterRoute onSubmit={onFilterSubmit} id={vieworder} filters={filters} onClose={setShowFilters} clearFilter={clearFilter}/> : null}
        {showDeleteRoute ? <DeleteRoute submitting={deleting} onSubmit={onRouteDelete} onClose={setShowDeleteRoute}/> : null}
    </div>
  )
}

const DeleteRoute = ({onSubmit,onClose,submitting})=>
{
    return <SmallPopup submitting={submitting} title={'Delete Route'} onClose={onClose} onSubmit={onSubmit} submitTitle={'Yes, Delete'}>
        <div>
            <p className="text-sm">Are you sure you want to delete the route?</p>
        </div>
    </SmallPopup>
}



const ManageRoute = ({setShow,onSubmit,edit=false})=>
{
    const [route,setRoute] = useState({id:null,routeName:'',routeDescription:'',routeCity:'',stops:[{stopName:'',stopNumber:1,isStartingPoint:false,isEndingPoint:false},{stopName:'',stopNumber:2,isStartingPoint:true,isEndingPoint:false},{stopName:'',stopNumber:4,isStartingPoint:false,isEndingPoint:true}]})
    const [loading,setLoading] = useState(edit ? true : false)
    const [cities,setCities] = useState([])
    useEffect(()=>
    {
        async function getRouteInfo(){
            if(edit)
            {
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route/${edit}?populate=true`)
                console.log(res.data.data.id)
                setRoute({id:res.data.data.id,routeName:res.data.data.routeName,routeDescription:res.data.data.routeDescription,stops:res.data.data.stops,routeCity:res.data.data.routeCity.id})
                // setLoading(false)
            }
        }
        async function getCities(){
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
                setCities(res.data.data)
                setLoading(false)
        }
        getRouteInfo()
        getCities()
    },[])


    const updateStop = (value,index)=>
    {
        setRoute((prev)=>
        {
            let newData = {...prev}
            newData.stops[index] = {...prev.stops[index],stopName:value} 
            return newData;
        })
    }

    const toggleStop = (add=true)=>
    {
        if(add) 
        {
            setRoute((prev)=>
            {
                let newData = {...prev}
                newData.stops = [{...prev.stops[0]},{...prev.stops[1]},{stopName:'',stopNumber:3,isStartingPoint:false,isEndingPoint:false},{...prev.stops[2]}]
                console.log('newdat',newData)
                return newData;
            })
        }
        else 
        {
            setRoute((prev)=>
            {
                let newData = {...prev}
                newData.stops = [{...prev.stops[0]},{...prev.stops[1]},{...prev.stops[3]}]
                return newData;
            })
        }
    }
    return loading ? 'loading' :<Popup onClose={()=>setShow({type:null,status:false})}  title={edit ?  'Edit Route' : 'Add Route'} submitTitle={edit ? 'Update' : 'Add'} formName={'createRoute'}>
        <form className='w-full' onSubmit={(e)=>onSubmit(e,route)} id="createRoute">
            <div>
                <label>Route Name</label>
                <Input placeholder={'Enter Route Name'} value={route.routeName} setValue={(value)=>setRoute(route=>({...route,routeName:value}))} required={true}/>
            </div>
            <div>
                <label>City</label>
                <Select placeholder={'Select City'} customLabel={'cityName'} options={cities} customValue={'id'} value={edit ? route.routeCity : route.routeCity} setValue={(value)=>setRoute(data=>({...data,routeCity:value}))}/>
            </div>
            <div>
                <label>Route Description</label>
                <Input placeholder={'Enter Route Description'} value={route.routeDescription} setValue={(value)=>setRoute(route=>({...route,routeDescription:value}))} required={false}/>
            </div>
            <div>
                <label>Route Stops</label>
                <div className="flex my-2 items-center">
                    <div className="w-3 h-3 bg-green-400 rounded-lg">
                    </div>
                    <p className="text-xs font-medium text-[#757575] my-0 ml-2 mr-4 tracking-tight w-[80px]">First Stop</p>
                <Input placeholder={'Enter Stop Name'} value={route.stops.length > 0 ? route.stops[0].stopName : ''} setValue={(value)=>updateStop(value,0)} required={true} padding={false}/>
                </div>
                {
                    route.stops.map((item,index)=>
                    {
                        if(parseInt(item?.stopNumber) === 1 || parseInt(item?.stopNumber) === 4) return false;
                        else if (item ) return <div className="flex my-2 items-center">
                        <div className="w-3 h-3 bg-gray-400 rounded-lg">
                            </div>
                            <p className="text-xs font-medium text-[#757575] my-0 ml-2 mr-4 tracking-tight w-[80px]">{index === 1 ? 'Second Stop' : 'Third Stop'}</p>
                        <Input placeholder={'Enter Stop Name'} value={route.stops[index].stopName} setValue={(value)=>updateStop(value,index)} required={true} padding={false}/>
                        <div>
                            {route.stops.length <= 3 && item.stopNumber === 2 ? <button type='button' className='btn-xs-inverted ml-2' onClick={()=>toggleStop(true)}>Add</button> : null}
                            {route.stops.length >= 3 && item.stopNumber === 3 ? <button type='button' className='btn-xs-inverted ml-2' onClick={()=>toggleStop(false)}>Remove</button> : null}
                        </div>
                        </div>

                    })
                }
                <div className="flex my-2 items-center">
                <div className="w-3 h-3 bg-red-400 rounded-lg">
                    </div>
                    <p className="text-xs font-medium text-[#757575] my-0 ml-2 mr-4 tracking-tight w-[80px]">Last Stop</p>
                <Input placeholder={'Enter Stop Name'} value={route.stops[route.stops.length-1].stopName} setValue={(value)=>updateStop(value,route.stops.length-1)} required={true} padding={false}/>
                </div>
            </div>
        </form>
    </Popup>
}