import React from 'react'
import AddIcon from '../../../images/add.svg'
import MinusIcon from '../../../images/minus.svg'
import Input from '../../../components/Input'

export default function ReturnInput({value=0,setValue,error=null}) {

    const onPress = (e)=>
    {
        const re = /^[0-9\b]+$/;
          if (e.target.value === '' || re.test(e.target.value)) {
            return true;
          }
          else return false;
    }

    const increment = ()=>
    {
        setValue(value ? parseInt(value)+1 : 1)
    }
    const decrement = ()=>
    {
        setValue(value ? parseInt(value)-1 : 0)

    }
    
  return (
    <div className='flex  w-auto ml-auto rounded-full'>
        <div className='w-[32px] h-[32px] p-2 rounded-l-md bg-[#ff5d5d] hover:bg-[#ff5d5d] cursor-pointer' onClick={decrement}>
            <img src={MinusIcon} className='w-full h-full'/>
        </div>
        <div>
            {/* <Input value={value} setValue={value=>setValue(value)}/> */}
            <input type={'text'} value={value} class={`${error ? 'error-input' : 'w-[48px] border-y border-gray-300 h-[32px] text-center text-[13px] focus:ring-0 focus:outline-0 focus:border-[#ff5d5d]'}`} 
 onChange={(e)=>setValue(e.target.value)} onKeyDown={onPress}/>
        </div>
        <div className='w-[32px] h-[32px] p-2 rounded-r-md bg-[#ff5d5d] hover:bg-[#ff5d5d] cursor-pointer' onClick={increment}>
            <img src={AddIcon} className='w-full h-full'/>
        </div>
    </div>
  )
}
