import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import CropPopup from "../../../components/CropPopup"
import CloseIcon from "../../../images/close.svg"
import { RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import StepTwo from "./StepTwo"
import StepOne from "./StepOne"
import StepThree from "./StepThree"
import { ErrorToast } from "../../../helpers/toasters"

export default function ManageOrder({onClose,edit=false,onOrderCreate,submitting})
{
    const [manageData,setManageData] = useState({orderRetailerId:'',orderDistributorId:'',orderSalesRepId:'',})
 
    const [showCrop,setShowCrop] = useState(false)
    const [image,setImage] = useState(null)
    const [step,setStep] = useState(1)
    const [products,setProducts] = useState([])

    const [selectedRoute,setSelectedRoute] = useState('')
    const [selectedCity,setSelectedCity] = useState('')
    const [cities,setCities] = useState([])
    const [retailers,setRetailers] = useState([])
    const [routes,setRoutes] = useState([])
    const [reps,setReps] = useState([])
    const [distributors,setDistributors] = useState([])


    useEffect(()=>
    {
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCities(res.data.data)
        }
        getCities()
    },[])

    const onStepSubmit = (e,nextStep)=>
    {
        try 
        {
            e.preventDefault()
            if(nextStep === 3 || nextStep === '3')
            {
                if(!products.some(product => product.orderQty && product.orderQty > 0)) throw "Add Atleast One Product";
                // else 
            }
            setStep(nextStep)    
        } catch (error) {
            ErrorToast(error)
        }
    }

    
    useEffect(()=>
    {
        async function getRoutes(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?routeCity=${selectedCity}`)
            setRoutes(res.data.data)
        }
        setDistributors([])
        setRetailers([])
        setReps([])
        if(selectedCity !== '') getRoutes()
    },[selectedCity])

useEffect(()=>
{
    async function getRetailers(){
        let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/retailer?routeId=${selectedRoute}`)
        setRetailers(res.data.data)
    }
    async function getDistributors(){
        let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/distributor?routeId=${selectedRoute}`)
        setDistributors(res.data.data)
    }
    async function getSalesRep(){
        let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/salesrepresentative?routeId=${selectedRoute}`)
        setReps(res.data.data)
    }
    if(selectedRoute !== '') getRetailers()
    if(selectedRoute !== '') getDistributors()
    if(selectedRoute !== '') getSalesRep()
    },[selectedRoute])


    useEffect(()=>
    {
        async function getProducts(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/product`)
            setProducts(res.data.data)
        }
        getProducts()
    },[])

    
    
    return <><MultiPagePopup submitting={submitting} step={step} setStep={setStep} title={edit ? 'Update Retailer' : 'Create Retailer'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={'createOrder'}>
        {step === 1 ? <StepOne onSubmit={onStepSubmit} manageData={manageData} setManageData={setManageData} setStep={setStep} cities={cities} retailers={retailers} routes={routes} distributors={distributors} selectedCity={selectedCity} selectedRoute={selectedRoute} setSelectedCity={setSelectedCity} setSelectedRoute={setSelectedRoute} reps={reps}/> : null}
        {step === 2 ? <StepTwo products={products} setProducts={setProducts} onSubmit={onStepSubmit} manageData={manageData} setManageData={setManageData} setStep={setStep}/> : null}
        {step === 3 ? <StepThree submitting={submitting} distributors={distributors} products={products} reps={reps} retailers={retailers} onSubmit={(e)=>onOrderCreate(e,manageData,products)} manageData={manageData} setManageData={setManageData} setStep={setStep}/> : null}
    </MultiPagePopup>
    </>
}



const MultiPagePopup = ({title,submitTitle,onClose,formName,children,step,setStep,submitting})=>
{
    
    return <div className='bg-black bg-opacity-70 fixed z-[999] w-[100%] h-full left-0 top-0 flex justify-center items-center overflow-scroll py-2'>
    <div className='w-[780px] max-w-full bg-white   top-0 h-auto right-[320px] rounded-md overflow-hidden'>
        <div className='flex px-8 py-4 justify-between items-center border-b-2 border-gray-100 bg-[#fff]'>
            <div>
                <h3 className='text-[14px] font-semibold tracking-[-.15px] capitalize'>{title}</h3>
                <p className="text-[11px] tracking-tight font-semibold text-[#959595] uppercase">{step === 1 ? 'Step 1: Select Info' : step === 2 ? 'Step 2: Add Products' : 'Step 3: Review Order'}</p>
            </div>
            <div className='bg-gray-100 px-2 py-2 rounded-md hover:bg-gray-200 transition-all cursor-pointer' onClick={()=>onClose(false)}>
              <img src={CloseIcon} className='w-5 h-5'/>
            </div>
        </div>
        <div className='flex px-8 py-6 w-full '>
            {children}
        </div>
        <div className='flex justify-end mt-4 px-8 py-4  bg-[#fafafa] border-t-2 border-gray-50'>
            <button type='button' className='btn-md-disabled' onClick={step !== 1 ? ()=>setStep(step-1) : ()=>onClose(false)}>{step !== 1 ? 'Back' : 'Cancel'}</button>
            <button form={'createOrder'} type='submit' disabled={submitting} className='ml-4 btn-md'>{step !== 3 ? 'Continue' : submitting ? 'Creating...' : 'Create'}</button>
        </div>
    </div>
</div>
}