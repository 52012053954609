import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import ManageRep from './components/ManageRep'
import ManageTeam from './components/ManageTeam'
import { LIMIT } from '../../helpers/constants'
import RightIcon from '../../images/right.svg'
import SortIcon from '../../images/sort.svg'
import FilterIcon from '../../images/filter.svg'
import FilterActiveIcon from '../../images/filteractive.svg'
import TeamFilter from './components/TeamFilter'
import { getDateFormat, getTimeFormat } from '../../helpers/utils'
import { Tooltip } from 'react-tooltip'
import { ErrorToast, InfoToast } from '../../helpers/toasters'

export default function Teams() {

    const [searchText,setSearchText] = useState('')
    const [teams,setTeams] = useState([])
    const [showManage,setShowManage] = useState({status:false,edit:null})

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const [filters,setFilters] = useState({routeId:null,cityId:null,status:null})
    const [showFilters,setShowFilters] = useState(false)
    const [sort,setSort] = useState('name');
    const [submitting,setSubmitting] = useState(false)

    const onFilterSubmit = (filters)=>
    {
        try 
        {
            setFilters({...filters})
            setCount(5)
            setOffset(0)
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const clearFilter = ()=>
    {
        try 
        {
            setFilters({routeId:null,cityId:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowManage({status:true,edit:null})}>Add Team</button>
        </div>
    }

    async function getTeams(){

        try 
        {
            let query = `&limit=${LIMIT}&offset=${offset}&orderBy=${sort}`;
            if(searchText) query+= `&search=${searchText}`;
            if(filters.cityId) query+=`&cityId=${filters.cityId.id}`
            if(filters.routeId) query+=`&routeId=${filters.routeId.id}`
            if(filters.status) query+=`&status=${filters.status}`
            if(sort.status) query+=`&status=${filters.status}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/teams?populate=true${query}`)
            // let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/teams?populate=true`)
            if(res.data.data) 
            {
                setTeams(res.data.data)
                setCount(res.data.count)
            }
        } catch (error) {
            toast('Error getting teams')
        }
    }

    useEffect(()=>
    {

        getTeams();
    },[filters,searchText,offset,sort])


    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }


    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            setSubmitting(true)
            let res;
            console.log('cities',data.cities)
            let cityIds = data.cities.map(item => item.id);
            if(showManage.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/teams/${showManage.edit}`,{...data,cities:cityIds}) 
            }
            else res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/teams`,{...data,cities:cityIds}) 
            if(res.data.data)
            {
                await getTeams()
                setShowManage(false)
                setSubmitting(false)
            }
            else toast('error updating retailer')
        } catch (error) {
            console.log(error.response.data.error)
            setSubmitting(false)
            ErrorToast(error.response.data.error)
        }
    }


  return (
    <>
        <Header title={'Sales Team Management'} RightContent={RightContent}/>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
            <div className='flex'> 
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Team'} label={'Search Team'} />
                <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180'/>
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center' onClick={()=>{if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }}}>
                        <img src={RightIcon} className='w-[20px] h-[20px]'/>
                    </div>
                </div>
            </div>
            <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(filters.cityId || filters.routeId || filters.status) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div>
        </div>
        <div className='flex flex-1 bg-white w-full overflow-scroll'>
            <table className='table-auto w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p onClick={()=>onSortPress('name')} className='cursor-pointer flex items-center hover:text-black'>Team Name {sort === 'name' || sort === '-name'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('city')} className='cursor-pointer flex items-center hover:text-black'>City {sort === 'city' || sort === '-city'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('status')} className='cursor-pointer flex items-center hover:text-black'>Status {sort === 'status' || sort === '-status'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('createdOn')} className='cursor-pointer flex items-center hover:text-black'>Joined On {sort === 'createdOn' || sort === '-createdOn'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('target')} className='cursor-pointer flex items-center hover:text-black'>Target {sort === 'target' || sort === '-target'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        teams.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.teamName}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        {/* <p className='text-sm font-regular my-0 '>{item.cityId?.cityName}</p> */}
                                        {item.cities.length > 0 && item.cities[0] ? <CityInfo data={item.cities} id={item.id}/> : <p className='text-sm font-regular my-0 '>Not Available</p>}
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className={`text-[12px] font-semibold px-3 inline-block py-1 rounded-md ${parseInt(item.status) ===  1 ? 'bg-[#3fdab6] bg-opacity-50 text-[#109e6c] ' : 'text-red-700 bg-red-200 bg-opacity-10'}}`}>{parseInt(item.status) ===  1 ? 'Active' : 'Inactive'}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{getDateFormat(item.created_date)}</p>
                                        <p className='text-[13px] text-gray-400 my-0'>{getTimeFormat(item.created_date)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0 '>{item.teamTarget ? item.teamTarget : 'Not Assigned'}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs' onClick={()=>setShowManage({status:true,edit:item.id})}>Edit</button>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showManage.status ? <ManageTeam submitting={submitting} onClose={setShowManage} onSubmit={onSubmit} edit={showManage.edit}/> : null}
        { showFilters ? <TeamFilter onSubmit={onFilterSubmit} filters={filters} onClose={setShowFilters} clearFilter={clearFilter}/> : null}
    </>
  )
}



const CityInfo = ({ data ,id}) => {

    return (
      <div>
        <p className='text-sm font-regular my-0 flex items-center' >{data.length > 0 ? data[0].cityName : 'Not Available'}{data.length > 1 ? <span data-tooltip-id={`${id}-city`} className='ml-1 text-[9px] font-semibold h-[16px] w-[16px] flex justify-center items-center text-center bg-black text-white rounded-lg'>{data.length > 1 ? ` +${data.length - 1}` : ''}</span> : null}
        </p>
          <Tooltip id={`${id}-city`} effect="solid" place="top" globalEventOff="click" clickable>
            {data.map((item, index) => (
              <p key={index}>{item.cityName}</p>
            ))}
          </Tooltip>

      </div>
    );
  };