import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"

export default function ManageDistributor({onClose,update=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState(!update ? {distributorCompanyName:'',distributorName:'',distributorContactNumber:'',distributorEmail:'',distributorCity:'',distributorRoute:'',distributorArea:'',distributorAddress:'',distributorActive:1,distributorImage:'test',distributorRoutes:[]} : {...updateData,distributorActive:parseInt(updateData.distributorActive),distributorLat:updateData.distributorLat,distributorLong:updateData.distributorLong,distributorTarget:updateData.distributorTarget,distributorRoutes:updateData.distributorRoutes,distributorAddress:updateData.distributorAddress})
    const [cities,setCities] = useState([])
    const [routes,setRoutes] = useState([])
    const [routeWithCities,setRoutesWithCities] = useState([])
    const formName = 'editDistributor';

    useEffect(()=>
    {
      console.log('addresss',manageData.distributorAddress)
    },[manageData])

    const updateDistributorRoutes = (cityId, routeId) => {
        setManageData((prevData) => {
          const existingCityIndex = prevData.distributorRoutes.findIndex(
            (city) => city.cityId === cityId
          );
    
          if (existingCityIndex !== -1) {
            // City exists, check for the route
            const existingRouteIndex = prevData.distributorRoutes[
              existingCityIndex
            ].routes.indexOf(routeId);
    
            if (existingRouteIndex !== -1) {
              // Route exists, remove it
              const updatedRoutes = [
                ...prevData.distributorRoutes[existingCityIndex].routes.slice(
                  0,
                  existingRouteIndex
                ),
                ...prevData.distributorRoutes[existingCityIndex].routes.slice(
                  existingRouteIndex + 1
                ),
              ];
    
              return {
                ...prevData,
                distributorRoutes: [
                  ...prevData.distributorRoutes.slice(0, existingCityIndex),
                  {
                    cityId,
                    routes: updatedRoutes,
                  },
                  ...prevData.distributorRoutes.slice(existingCityIndex + 1),
                ],
              };
            } else {
              // Route doesn't exist, add it
              return {
                ...prevData,
                distributorRoutes: [
                  ...prevData.distributorRoutes.slice(0, existingCityIndex),
                  {
                    cityId,
                    routes: [...prevData.distributorRoutes[existingCityIndex].routes, routeId],
                  },
                  ...prevData.distributorRoutes.slice(existingCityIndex + 1),
                ],
              };
            }
          } else {
            // City doesn't exist, add a new entry
            return {
              ...prevData,
              distributorRoutes: [
                ...prevData.distributorRoutes,
                {
                  cityId,
                  routes: [routeId],
                },
              ],
            };
          }
        });
      };

      

    useEffect(()=>
    {
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCities(res.data.data)
        }
        async function getCitiesWithRoutes(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/routes-cities`)
            setRoutesWithCities(res.data.data)
        }
        getCities()
        getCitiesWithRoutes()
    },[])

    useEffect(()=>
    {
        async function getRoutes(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?routeCity=${manageData.distributorCity}`)
            setRoutes(res.data.data)
        }
        if(manageData.distributorCity !== '') getRoutes()
    },[manageData.distributorCity])



    return <Popup size="xl" title={update ? 'Update Distributor' : 'Create Distributor'} submitTitle={update ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,manageData)} id={formName} className='grid grid-cols-2 gap-y-0 w-full'>
            <div className='grid grid-cols-4 gap-x-2 gap-y-0 w-full border-r border-x-gray-200 pr-4'>
            <div className="col-span-2">
                <label>Company Name</label>
                <Input type='text' placeholder={'Enter Company Name'} value={manageData.distributorCompanyName} setValue={(value)=>setManageData(data=>({...data,distributorCompanyName:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Owner Name</label>
                <Input type='text' placeholder={'Enter Company Name'} value={manageData.distributorName} setValue={(value)=>setManageData(data=>({...data,distributorName:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Phone Number (+91)</label>
                <Input type='text' placeholder={'Enter Phone Number'} value={manageData.distributorContactNumber} setValue={(value)=>setManageData(data=>({...data,distributorContactNumber:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Email</label>
                <Input type='text' placeholder={'Enter Email'} value={manageData.distributorEmail} setValue={(value)=>setManageData(data=>({...data,distributorEmail:value}))}/>
            </div>
            {/* <div>
                <label>City</label>
                <Select placeholder={'Select City'} customLabel={'cityName'} options={cities} customValue={'id'} value={manageData.distributorCity} setValue={(value)=>setManageData(data=>({...data,distributorCity:value}))}/>
            </div>
            <div>
                <label>Route</label>
                <Select placeholder={'Select Route'} customLabel={'routeName'} options={routes} customValue={'id'} value={manageData.distributorRoute} setValue={(value)=>setManageData(data=>({...data,distributorRoute:value}))}/>
            </div> */}
            <div className="col-span-2">
                <label>Area</label>
                <Input type='text' placeholder={'Enter area'} value={manageData.distributorArea} setValue={(value)=>setManageData(data=>({...data,distributorArea:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Target</label>
                <Input type='text' placeholder={'Enter Target'} value={manageData.distributorTarget} setValue={(value)=>setManageData(data=>({...data,distributorTarget:value}))}/>
            </div>
            <div className="col-span-1">
                <label>Status</label>
                <Select size="sm" placeholder={'Select Status'} options={[{name:'Active',value:1},{name:'Inactive',value:0}]}  value={manageData.distributorActive} setValue={(value)=>setManageData(data=>({...data,distributorActive:value}))}/>
            </div>
            <div className="col-span-3">
                <label>Address</label>
                <textarea className="h-[40px] w-full text-input" placeholder={'Enter Address'} value={manageData.distributorAddress} onChange={(e)=>setManageData(data=>({...data,distributorAddress:e.target.value}))}/>
            </div>
            <div className='col-span-4'>
                <Map defaultLocation={{lat:manageData.distributorLat,long:manageData.distributorLong}} setLocation={(lat,lang)=>setManageData(data=>({...data,distributorLat:lat,distributorLong:lang}))}/>
            </div>
            </div>
            <div className="h-[420px] overflow-scroll border-l border-x-gray-200 pl-4">
                {
                    routeWithCities.map((item,index)=><CityItem manageData={manageData} updateDistributorRoutes={updateDistributorRoutes} item={item} key={index}/>)
                }
            </div>
        </form>
    </Popup>
}


const CityItem = ({item,updateDistributorRoutes,manageData})=>
{
    return <div className="py-2 border-b border-b-gray-200 px-4">
        <p className="font-semibold text-sm">{item.cityName}</p>
        <div className="flex flex-wrap gap-2 mt-2">
        {
            item.routes.map((i,index)=>
            {
                return <RouteItem manageData={manageData} updateDistributorRoutes={updateDistributorRoutes} item={i} key={index} cityId={item.cityId}/>
            })
        }
        </div>
    </div>
}

const RouteItem = ({item,updateDistributorRoutes,cityId,manageData})=>
{
    const isRouteSelected = manageData.distributorRoutes.some(
        (city) => city.cityId === cityId && city.routes.includes(item.routeId)
      );

    const handleRouteClick = () => {
        // Call the updateDistributorRoutes function to update the state
        updateDistributorRoutes(cityId, item.routeId);
      };

    return <div className={`py-2 border border-gray-200 py-2 px-2 rounded-full cursor-pointer ${isRouteSelected ? 'bg-[#2ac08b]' : 'bg-[#f3f3f3]'}`} onClick={handleRouteClick}>
        <p className="font-regular text-xs">{item.routeName}</p>
    </div>
}