import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import SearchInput from '../../../components/SearchInput'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import TargetUI from '../../../components/TargetUI'
import { Tooltip } from 'react-tooltip'
// import ManageRep from '../components/ManageRep'

export default function RepLeaderboard({month}) {

    const [searchText,setSearchText] = useState('')
    const [leaderboard,setLeaderboard] = useState([])
    const [showManage,setShowManage] = useState({status:false,edit:null})

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    // const RightContent = ()=>
    // {
    //     return <div>
    //       <button type='button' className='btn-md' onClick={()=>setShowManage({status:true,edit:null})}>Add Retailer</button>
    //     </div>
    // }

    function convertMonthYearFormat(date) {
            const month = date.getMonth() + 1; // Adding 1 because getMonth() returns 0-11
            const year = date.getFullYear();
            
            const monthString = month < 10 ? `0${month}` : `${month}`;
            
            return `${monthString}-${year}`;
    }

    async function getReps(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/leaderboardrep?populate=true&month_and_year=${convertMonthYearFormat(month)}`)
            if(res.data.data) setLeaderboard(res.data.data)
        } catch (error) {
                console.log(error)
            toast('Error getting retailer')
        }
    }
    useEffect(()=>
    {
        getReps();
    },[month])

    useEffect(()=>
    {
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCityFilters(res.data.data)
        }
        getCities()
    },[])

    useEffect(()=>
    {
        async function getRoutes(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?routeCity=${selectedFilters.city}`)
            if(res.data.data)  setRouteFilters(res.data.data)
        }
        if(selectedFilters.city !== '') getRoutes()
        else {
            setRouteFilters([]);
            setSelectedFilters(data=>({...data,route:''}))
        }
    },[selectedFilters.city])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            console.log(data)
            // let base64Image = await getBase64Image(data.retailerShopImage)
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/salesrepresentative`,{...data}) 
            if(res.data.data)
            {
                await getReps()
                toast.info('Sales Rep Added')
                setShowManage({edit:null,status:false})
            }
            else toast('error updating retailer')
        } catch (error) {
            console.log(error)
            toast(error.response)
        }
    }


  return (
    <>
        <div className=' flex-1 bg-white w-full overflow-scroll'>
        {/* <div className='flex-auto flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
            <div>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Retailer'} label={'Search Retailer'} />
            </div>
            <div className='flex justify-end items-stretch h-[100%] self-stretch'>
            <div>
                <FilterSelect placeholder={'Select Month'} options={routeFilters} value={selectedFilters.route} customLabel={'routeName'} customValue={'id'} setValue={(value)=>setSelectedFilters(data=>({...data,route:value}))} padding={false}/>
            </div>
            </div>
        </div> */}
            <table className='table-auto w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Name/Team</p></td>
                    <td><p>Phone/Email</p></td>
                    <td><p>City</p></td>
                    <td><p>Target</p></td>
                    {/* <td><p>Action</p></td> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        leaderboard.map((item,index)=>
                        {
                            console.log('check',item.cities && item.cities.length > 0 && item.cities[0].cityInfo?.cityName)
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-medium my-0'>{item.repId.firstName}</p>
                                        <p className='text-[13px] text-gray-400 my-0'>{item.repId.repTeam?.teamName}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.repId.repContactNumber}</p>
                                        <p className='text-[13px] text-gray-400 my-0 lowercase'>{item.repId.repEmail}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        {item.cities && item.cities.length > 0 && item.cities[0].cityInfo?.cityName ? <CityRouteInfo id={item.repId.id} data={item.cities} key={item.repId.id}/> : <p className='text-sm font-regular my-0'>Not Available</p>}
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-medium my-0'>{parseFloat(item.percentage).toFixed(2)}%</p>
                                        <TargetUI percentage={item.percentage ? item.percentage : 0}/>
                                        <p className='text-[13px] text-gray-400 my-0 lowercase'>{item.score ? item.score : 0} of {item.repId.repTarget ? item.repId.repTarget : 0} Pts</p>
                                    </div>
                                </td>
                                {/* <td className='capitalize'>
                                <div>
                                    <button className='btn-xs' onClick={()=>navigate(`/sales-representatives/${item.id}`)}>View</button>
                                    </div>
                                </td> */}
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        {/* { showManage.status ? <ManageRep onClose={setShowManage} onSubmit={onSubmit} edit={showManage.edit}/> : null} */}
    </>
  )
}



// const CityRouteInfo = ({ data ,id}) => {

//     let routes = data.flatMap(item => item.routeIds.map(routeId => {
//         const routeInfo = routeId.routeName; // Adjust this based on your route information
//         return routeInfo;
//     }
//     )
//     );


//     return (
//       <div>
//         <p className='text-sm font-regular my-0 flex items-start' >{data.length > 0 ? data[0].cityInfo.cityName : 'Not Available'}{data.length > 1 ? <span data-tooltip-id={`${id}-city`} className='ml-1 text-[9px] font-semibold h-[16px] w-[16px] flex justify-center items-center text-center bg-black text-white rounded-lg'>{data.length > 1 ? ` +${data.length - 1}` : ''}
//         </span> : null}
//         </p>
//         <p className='text-[13px] text-gray-400 my-0' >{data.length > 0 ? data[0].routeIds[0].routeName : 'Not Available'}{routes.length > 1 ?<span data-tooltip-id={`${id}-route`} className='inline px-1 py-1 self-end ml-1 text-[9px] font-semibold w-[100%] h-[100%] !max-h-[16px] !max-w-[16px]  justify-center items-center text-center bg-black text-white rounded-full'>{routes.length > 1 ? `+${routes.length - 1}` : ''}</span> : null}</p>
  
//           <Tooltip id={`${id}-city`} effect="solid" place="top" globalEventOff="click" clickable>
//             {data.map((item, index) => (
//               <p className='text-xs' key={index}>{item.cityInfo.cityName}</p>
//             ))}
//           </Tooltip>

//           <Tooltip id={`${id}-route`} effect="solid" place="top" globalEventOff="click" clickable>
//                { routes.map((item, index) => (
//                     <p className='text-xs' key={index}>{item}</p>
//                 ))}
//           </Tooltip>
//       </div>
//     );
//   };

const CityRouteInfo = ({ data ,id}) => {
    console.log('data itesm id',id)
    let routes = data.flatMap(item => item.routeIds.map(routeId => {
        const routeInfo = routeId.routeName; // Adjust this based on your route information
        return routeInfo;
    }));


    return (
      <div>
        <p className='text-sm font-regular my-0 flex items-start' >{data.length > 0 ? data[0].cityInfo.cityName : 'Not Available'}{data.length > 1 ? <span data-tooltip-id={`${id}-rep-city`} className='ml-1 text-[9px] font-semibold h-[16px] w-[16px] flex justify-center items-center text-center bg-black text-white rounded-lg'>{data.length > 1 ? ` +${data.length - 1}` : ''}
        </span> : null}
        </p>
        <p className='text-[13px] text-gray-400 my-0' >{data.length > 0 ? data[0].routeIds[0].routeName : 'Not Available'}{routes.length > 1 ?<span data-tooltip-id={`${id}-rep-route`} className='inline px-1 py-1 self-end ml-1 text-[9px] font-semibold w-[100%] h-[100%] !max-h-[16px] !max-w-[16px]  justify-center items-center text-center bg-black text-white rounded-full'>{routes.length > 1 ? `+${routes.length - 1}` : ''}</span> : null}</p>
  
          <Tooltip id={`${id}-rep-city`} effect="solid" place="top" globalEventOff="click" clickable>
            {data.map((item, index) => (
              <p className='text-xs' key={index}>{item.cityInfo.cityName}</p>
            ))}
          </Tooltip>

          <Tooltip id={`${id}-rep-route`} effect="solid" place="top" globalEventOff="click" clickable>
               { routes.map((item, index) => (
                    <p className='text-xs' key={index}>{item}</p>
                ))}
          </Tooltip>
      </div>
    );
  };

