import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Green Tea 500gm', 'Coffee','Tea'],
  datasets: [
    {
      label: 'Orders',
      data: [12, 3, 19],
      
      borderWidth: 0,
    },
  ],
};

export function ProductOrders({data=[]}) {

  let labels = [];
  let items = [];
  
  data.map((item,index)=>
  {
    labels.push(item.productName ? item.productName : 'Others')
    items.push(item.totalQty)
  })

    const info = {
      labels,
      datasets: [
        {
          label: 'Qty',
          data: items,
          backgroundColor: [
            '#ffd452',
            '#2980B9',
            '#8E2DE2',
            '#1f1FF1',
            '#f64f59',
            '#ee9ca7',
            '#2c3e50',
            // '#8E2DE2',
          ],
          borderRadius:4,
        }
      ],
    };

  return <div className='bg-white border border-gray-200 overflow-hidden rounded-md w-auto h-[280px] p-4'>
        <p className='text-sm font-semibold tracking-tight mb-4'>Quantities By Products</p>
        <div className='w-auto h-[200px] m-auto flex justify-center'>
      <Doughnut data={info} options={{plugins:{legend:{position:'center',display:true}}}} style={{borderRadius:12,accentColor:'#959595'}} />;
        </div>
    </div>
}
