async function dataURItoBlob(url){
    return await (await fetch(url)).blob()
}

export function getDateFormat(date) {
    const inputDate = new Date(date);
  
    const day = String(inputDate.getDate()).padStart(2, "0");
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const year = inputDate.getFullYear();
  
    return `${day}-${month}-${year}`;
  }

export function getValidDateFormat(date) {
    const inputDate = new Date(date);
  
    const day = String(inputDate.getDate()).padStart(2, "0");
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const year = inputDate.getFullYear();
  
    return `${year}-${month}-${day}`;
  }
  
  export function getTimeFormat(date) {
    const inputDate = new Date(date);

    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format

    const formattedMinutes = String(minutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes} ${amPm}`;
}


export function getDayFromDate(dateString) {
    // Create a new Date object from the input date string
    const date = new Date(dateString);
  
    // Get the day of the week (0 for Sunday, 1 for Monday, and so on)
    const dayOfWeek = date.getDay();
  
    // Define an array of day names
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  
    // Return the day name based on the day of the week
    return dayNames[dayOfWeek];
  }


function base64ToBlob(base64, mime) 
{
    mime = mime || 'image/jpeg';
    var sliceSize = 1024;
    var byteChars = window.atob(base64);
    var byteArrays = [];

    for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        var slice = byteChars.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: mime});
}


function renderWeight(value) 
{
    let val = parseInt(value)
    if(val > 1000) return `${value/1000}Kg`
    else return `${value} Gm`
}

export {dataURItoBlob,base64ToBlob,renderWeight}