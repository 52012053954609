import React from 'react'
import AddIcon from '../../../images/add.svg'
import MinusIcon from '../../../images/minus.svg'
import Input from '../../../components/Input'

export default function FreeItemInput({type=0,value='',setValue,error=null}) {

    const onPress = (e)=>
    {
        const re = /^[0-9\b]+$/;
          if (e.target.value === '' || re.test(e.target.value)) {
            return true;
          }
          else return false;
    }


  return (
    <div className='flex  w-auto border rounded-md overflow-hidden'>

        <div>
            <input type={'text'} value={value} class={`${error ? 'error-input' : 'w-[58px] border-0  border-gray-300 h-[32px] text-center text-[13px] focus:ring-0 focus:outline-0 focus:border-[#2ac08b]'}`} 
 onChange={(e)=>setValue(e.target.value)} onKeyDown={onPress}/>
        </div>
    </div>
  )
}
