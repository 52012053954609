import { useEffect, useState } from "react"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import Input from "../../../components/Input"
import OrderInput from "./OrderInput"
import OfferInput from "./OfferInput"
import FreeItemInput from "./FreeItemInput"
import ReturnInput from "./ReturnInput"

export default function StepTwo ({manageData,setManageData,setStep,products,setProducts,onSubmit})
{

    const updateQty = (value,productId)=>
    {
        setProducts(prevState=>{
            let newData = [...prevState]
            let itemIndex = products.findIndex(item=>item.id===productId)
            newData[itemIndex].orderQty=value
            console.log(newData)
            return newData;
        })
    }

    const updateReturnQty = (value,productId)=>
    {
        setProducts(prevState=>{
            let newData = [...prevState]
            let itemIndex = products.findIndex(item=>item.id===productId)
            newData[itemIndex].itemQty=value
            console.log(newData)
            return newData;
        })
    }
    
    const updateOfferValue = (value,productId)=>
    {
        setProducts(prevState=>{
            let newData = [...prevState]
            let itemIndex = products.findIndex(item=>item.id===productId)
            newData[itemIndex].itemOfferValue=value
            console.log(newData)
            return newData;
        })
    }
    const updateOfferType = (value,productId)=>
    {
        setProducts(prevState=>{
            let newData = [...prevState]
            let itemIndex = products.findIndex(item=>item.id===productId)
            newData[itemIndex].itemOfferType=value
            console.log(newData)
            return newData;
        })
    }

    const updateFreeItem = (value,productId)=>
    {
        setProducts(prevState=>{
            let newData = [...prevState]
            let itemIndex = products.findIndex(item=>item.id===productId)
            newData[itemIndex].itemFreeItem=value
            console.log(newData)
            return newData;
        })
    }
    

    return <form onSubmit={(e)=>onSubmit(e,3)} id={'createOrder'} className='w-full h-[400px] overflow-y-scroll'>
        <table className="table-fixed">
            <tbody>
    {
        products.map((item,index)=>
        {
            return <tr className="px-0 mx-0 w-[100%]">
                <td className="px-0 py-2">
                    <div className="w-[40px] h-[40px]">
                    <img src={item.productImage} className="w-[40px] h-[40px] rounded-full"/>
                    </div>
                </td>
                <td className="block px-0 pr-4 py-2">
                    <p className="text-[13px] font-medium tracking-tight">{item.productName}</p>
                    <p className="text-[11px] text-[#959595] font-regular">{item.productSku}</p>
                </td>
                <td className="block px-4 py-2">
                    <p className="text-[13px] font-medium tracking-tight"><span className="text-xs text-[#959595]">Rs.</span>{item.productPrice}</p>
                    <p className="text-[11px] text-[#959595] font-regular">{item.productWeight} Gms</p>
                </td>
                <td className="px-0 pr-2 py-2 flex content-end items-center">
                    <OrderInput value={products[index].orderQty} setValue={(value)=>updateQty(value,products[index].id)}/>
                </td>
                <td className="px-0 pr-2 py-2 flex content-end items-center">
                    <OfferInput value={products[index].itemOfferValue} type={products[index].itemOfferType} updateOfferValue={(value)=>updateOfferValue(value,products[index].id)} updateOfferType={(value)=>updateOfferType(value,products[index].id)}/>
                </td>
                <td className="px-0 pr-2 py-2 flex content-end items-center">
                    <FreeItemInput value={products[index].itemFreeItem} setValue={(value)=>updateFreeItem(value,products[index].id)}/>
                </td>
                <td className="px-0 py-2 flex content-end items-center">
                    <ReturnInput value={products[index].itemQty} setValue={(value)=>updateReturnQty(value,products[index].id)}/>
                </td>
        </tr>
        })
    }
    </tbody>
    </table>
</form>
}