import React from 'react'
import { Link } from 'react-router-dom'

export default function Header({title,RightContent,parentLink='',parentTitle=''}) {
  return (
    <div className=' px-6 py-4 bg-white flex justify-between items-center border-b border-b-[#EDF2F5] border-b-[#e3e3e3]'>
        <div>
        <p className='text-xs font-normal capitalize tracking-[-.15px] text-gray-500'><Link to={'/'}>Home</Link> {parentLink ? '>' : null} <Link className='hover:text-[#2ac08b]' to={`/${parentLink}`}>{parentTitle}</Link></p>
        <h2 className='text-[15px] font-semibold capitalize tracking-[-.05px]'>{title}</h2>
        </div>
        <RightContent/>
    </div>
  )
}
