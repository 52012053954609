import React, { useEffect, useState } from 'react'
import StatusOrders from './components/StatusOrders'
import { RouteOrders } from './components/RouteOrders'
import { CityOrders } from './components/CityOrders'
import { PriceData } from './components/PriceData'
import { ProductOrders } from './components/ProductOrders'
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import axios from 'axios'
import Input from '../../components/Input'
import { getDateFormat, getValidDateFormat } from '../../helpers/utils'
import Loader from '../../components/Loader'
import { TeamOrders } from './components/TeamOrders'
import { RepOrders } from './components/RepOrders'
import { RepPoints } from './components/RepPoints'
import { RoutePoints } from './components/RoutePoints'
import { CityPoints } from './components/CityPoints'
import { TeamPoints } from './components/TeamPoints'

export default function Dashboard() {
  
  const [type,setType] = useState('1D')
  const [counts,setCounts] = useState('')
  const [analytics,setAnalytics] = useState(null)
  const [startDate,setStartDate] = useState('')
  const [endDate,setEndDate] = useState('')
  const [loading,setLoading] = useState(true)


  // const onPress = (value) => {
  //   const today = new Date();
  //   setType(value)
  //   if (value === '1D') {
  //     // Set startDate and endDate to today
  //     setStartDate(today);
  //     setEndDate(today);
  //   } else if (value === '1M') {
  //     // Calculate startDate as 1 month before today
  //     const oneMonthAgo = new Date(today);
  //     oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  //     setStartDate(oneMonthAgo);
  //     setEndDate(today);
  //   } else if (value === '1W') {
  //     // Calculate startDate as 1 week before today
  //     const oneWeekAgo = new Date(today);
  //     oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  //     setStartDate(oneWeekAgo);
  //     setEndDate(today);
  //   }
  // };

  const onPress = (value) => {
    const today = new Date();
    setType(value);
  
    if (value === '1D') {
      // Set startDate and endDate to today
      setStartDate(today);
      setEndDate(today);
    } else if (value === '1W') {
      // Calculate startDate as 1 week before today
      const oneWeekAgo = new Date(today);
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  
      setStartDate(oneWeekAgo);
      setEndDate(today);
    } else if (value === '1M') {
      // Calculate startDate as 1 month before today
      const oneMonthAgo = new Date(today);
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  
      setStartDate(oneMonthAgo);
      setEndDate(today);
    } else if (value === '3M') {
      // Calculate startDate as 3 months before today
      const threeMonthsAgo = new Date(today);
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  
      setStartDate(threeMonthsAgo);
      setEndDate(today);
    } else if (value === '6M') {
      // Calculate startDate as 6 months before today
      const sixMonthsAgo = new Date(today);
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
  
      setStartDate(sixMonthsAgo);
      setEndDate(today);
    } else if (value === '1Y') {
      // Calculate startDate as 1 year before today
      const oneYearAgo = new Date(today);
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  
      setStartDate(oneYearAgo);
      setEndDate(today);
    } else if (value === 'All') {
      // Set both startDate and endDate to empty strings
      setStartDate('');
      setEndDate('');
    }
  };
  

  useEffect(()=>
  {
    async function getOrderAnalytics()
    {
      if (type === '1D')
      {
        const today = new Date();
        let query = ''
        query +=`startDate=${getValidDateFormat(today)}`
        query +=`&endDate=${getValidDateFormat(today)}`;
        let res = await axios.get(`${process.env.REACT_APP_BASE_URL}/analytics/admin?${query}`)
        setAnalytics(res.data.data)
        setLoading(false)
      }
      else
      {

        let query = ''
        if(startDate) query +=`startDate=${getValidDateFormat(startDate)}`
        if(endDate) query +=`&endDate=${getValidDateFormat(endDate)}`;
        let res = await axios.get(`${process.env.REACT_APP_BASE_URL}/analytics/admin?${query}`)
        setAnalytics(res.data.data)
        setLoading(false)
      }
    }
    getOrderAnalytics()
  },[startDate,endDate])

  useEffect(()=>
  {
    async function getCounts()
    {
        let res = await axios.get(`${process.env.REACT_APP_BASE_URL}/analytics/count`)
        setCounts(res.data.data)
    }
    getCounts()
  },[])


  const tabItems = [{label:'This Month',value:'this_month'},{label:'Last Month',value:'last_month'}]
  const [activeTab,setActiveTab] = useState('this_month')
  return (
    !loading ? <div className='px-6 py-4 overflow-scroll'>
        <div className="grid grid-cols-7 flex-wrap gap-y-3 bg-white border border-gray-200 overflow-hidden rounded-md">
          <div className='border-r border-gray-200 py-6 px-6'>
            <p className='text-2xl font-bold mb-0'>{counts?.products ? counts.products : '-'}</p>
            <p className='text-sm font-medium text-[#959595] mt-0'>Products</p>
          </div>
          <div className='border-r border-gray-200 py-6 px-6'>
            <p className='text-2xl font-bold mb-0'>{counts?.salesReps ? counts.salesReps : '-'}</p>
            <p className='text-sm font-medium text-[#959595] mt-0'>Sales Rep.</p>
          </div>
          <div className='border-r border-gray-200 py-6 px-6'>
            <p className='text-2xl font-bold mb-0'>{counts?.products ? counts.products : '-'}</p>
            <p className='text-sm font-medium text-[#959595] mt-0'>Teams</p>
          </div>
          <div className='border-r border-gray-200 py-6 px-6'>
            <p className='text-2xl font-bold mb-0'>{counts?.retailers ? counts.retailers : '-'}</p>
            <p className='text-sm font-medium text-[#959595] mt-0'>Retailers</p>
          </div>
          <div className='border-r border-gray-200 py-6 px-6'>
            <p className='text-2xl font-bold mb-0'>{counts?.distributors ? counts.distributors : '-'}</p>
            <p className='text-sm font-medium text-[#959595] mt-0'>Distributors</p>
          </div>
          <div className='border-r border-gray-200 py-6 px-6'>
            <p className='text-2xl font-bold mb-0'>{counts?.products ? counts.products : '-'}</p>
            <p className='text-sm font-medium text-[#959595] mt-0'>Cities</p>
          </div>
          <div className='border-r border-gray-200 py-6 px-6'>
            <p className='text-2xl font-bold mb-0'>{counts?.routes ? counts.routes : '-'}</p>
            <p className='text-sm font-medium text-[#959595] mt-0'>Routes</p>
          </div>
        </div>
        <div className='my-4 flex justify-between items-center'>
        <p className='text-sm font-medium tracking-tight  text-gray-700'>Total Orders- {analytics?.orderStatuses?.totalOrders}</p>
        <div className=' flex items-center'>
            <div className='relative flex mr-4 bg-white border border-gray-200 rounded-md overflow-hidden'>
              <div className={`px-3 py-2 periodTabItem border-r border-gray-200 transition-colors cursor-pointer text-[#757575] hover:bg-[#2ac08b] hover:text-white ${type === '1D' ? 'bg-[#2ac08b] text-white' : ''}`} onClick={()=>onPress('1D')}>
                <p className='text-xs font-medium capitalize tracking-tight'>1D</p>
              </div>
              <div className={`px-3 py-2 periodTabItem border-r border-gray-200 transition-colors cursor-pointer text-[#757575] hover:bg-[#2ac08b] hover:text-white ${type === '1W' ? 'bg-[#2ac08b] text-white' : ''}`} onClick={()=>onPress('1W')}>
                <p className='text-xs font-medium capitalize tracking-tight'>1W</p>
              </div>
              <div className={`px-3 py-2 periodTabItem border-r border-gray-200 transition-colors cursor-pointer text-[#757575] hover:bg-[#2ac08b] hover:text-white ${type === '1M' ? 'bg-[#2ac08b] text-white' : ''}`} onClick={()=>onPress('1M')}>
                <p className='text-xs font-medium capitalize  tracking-tight'>1M</p>
              </div>
              <div className={`px-3 py-2 periodTabItem border-r border-gray-200 transition-colors cursor-pointer text-[#757575] hover:bg-[#2ac08b] hover:text-white ${type === '3M' ? 'bg-[#2ac08b] text-white' : ''}`} onClick={()=>onPress('3M')}>
                <p className='text-xs font-medium capitalize  tracking-tight'>3M</p>
              </div>
              <div className={`px-3 py-2 periodTabItem border-r border-gray-200 transition-colors cursor-pointer text-[#757575] hover:bg-[#2ac08b] hover:text-white ${type === '6M' ? 'bg-[#2ac08b] text-white' : ''}`} onClick={()=>onPress('6M')}>
                <p className='text-xs font-medium capitalize  tracking-tight'>6M</p>
              </div>
              <div className={`px-3 py-2 periodTabItem border-r border-gray-200 transition-colors cursor-pointer text-[#757575] hover:bg-[#2ac08b] hover:text-white ${type === '1Y' ? 'bg-[#2ac08b] text-white' : ''}`} onClick={()=>onPress('1Y')}>
                <p className='text-xs font-medium capitalize tracking-tight'>1Y</p>
              </div>
              <div className={`px-3 py-2 periodTabItem border-r border-gray-200 transition-colors cursor-pointer text-[#757575] hover:bg-[#2ac08b] hover:text-white ${type === 'All' ? 'bg-[#2ac08b] text-white' : ''}`} onClick={()=>onPress('All')}>
                <p className='text-xs font-medium capitalize tracking-tight'>All</p>
              </div>
              </div>
            <div className='relative flex  bg-white border border-gray-200 rounded-md overflow-hidden'>
              <div onClick={()=>onPress(true)} className='border-r'>
              {/* <Input type='date' placeholder={'Select Start Date'} value={startDate} setValue={(value)=>setStartDate(value)}/> */}
              <input className='px-3 py-2 periodTabItem mb-0 text-[12px]' type='date' placeholder={'Select Start Date'} value={startDate} onChange={(e)=>setStartDate(e.target.value)}/>
              </div>
              <div onClick={()=>onPress(true)}>
              <input className='px-3 py-2 periodTabItem mb-0 text-[12px]' type='date' placeholder={'Select End Date'} value={endDate} onChange={(e)=>setEndDate(e.target.value)}/>
              </div>
              </div>
        </div>
        </div>
        <div className='grid grid-cols-4 gap-3 mt-4'>
          <div className='col-span-1 w-auto h-auto'>
            <StatusOrders data={analytics?.orderStatuses}/>
          </div>
          <div className='col-span-1 w-auto h-auto'>
          <div className='bg-white border border-gray-200 overflow-hidden rounded-md w-auto h-[280px] p-4'>
              <p className='text-sm font-semibold tracking-tight mb-4'>Totals</p>
              <div className='w-auto h-[200px] m-auto justify-center'>
                <div className='flex justify-between items-center border-b border-b-gray-200 px-2 py-4'>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>Weight</p>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>{analytics.points ? (parseFloat(analytics.points.orderWeight) / 1000).toFixed(2) : '-'} {!isNaN(analytics.points.orderWeight) && analytics.points.orderWeight > 0 ? 'Kg' : null}</p>
                </div>
                <div className='flex justify-between items-center border-b border-b-gray-200 px-2 py-4'>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>Home Points (&lt; 1Kg)</p>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>{analytics.points ? parseFloat(analytics.points.homePoint).toFixed(2) :'-'}</p>
                </div>
                <div className='flex justify-between items-center border-b border-b-gray-200 px-2 py-4'>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>Hotel Points (&gt;=1Kg)</p>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>{analytics.points ? parseFloat(analytics.points.hotelPoint).toFixed(2) :'-'}</p>
                </div>
                <div className='flex justify-between items-center border-b border-b-gray-200 px-2 py-4'>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>Reward Points</p>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>{analytics.points ? parseFloat(analytics.points.rewardPoints).toFixed(2) :'-'}</p>
                </div>
              </div>
          </div>
          </div>
          <div className='col-start-3 col-span-1 w-auto h-full'>
          <div className='bg-white border border-gray-200 overflow-hidden rounded-md w-auto h-[280px] p-4'>
              <p className='text-sm font-semibold tracking-tight mb-4'>Average Reward Points</p>
              <div className='w-auto h-[200px] m-auto justify-center'>
                <div className='flex justify-between items-center border-b border-b-gray-200 px-2 py-4'>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>Per Sales Rep</p>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>{analytics.averageRewardPoints ? parseFloat(analytics.averageRewardPoints.salesRep).toFixed(2) : '-'}</p>
                </div>
                <div className='flex justify-between items-center border-b border-b-gray-200 px-2 py-4'>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>Per Retailer</p>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>{analytics.averageRewardPoints ? parseFloat(analytics.averageRewardPoints.retailer).toFixed(2) :'-'}</p>
                </div>
                <div className='flex justify-between items-center border-b border-b-gray-200 px-2 py-4'>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>Per Distributor</p>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>{analytics.averageRewardPoints ? parseFloat(analytics.averageRewardPoints.distributor).toFixed(2) :'-'}</p>
                </div>
                <div className='flex justify-between items-center border-b border-b-gray-200 px-2 py-4'>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>Per Team</p>
                    <p className='text-sm font-medium tracking-tight  text-gray-700'>{analytics.averageRewardPoints ? parseFloat(analytics.averageRewardPoints.team).toFixed(2) :'-'}</p>
                </div>
              </div>
          </div>
          </div>
          <div className='col-start-4 col-span-1 w-auto h-full'>
            <ProductOrders data={analytics?.productOrders}/>
          </div>

        </div>
        <div className='grid grid-cols-4 gap-3 mt-4'>
          <div className='col-start-0 col-span-2 w-auto h-auto'>
            <RouteOrders data={analytics?.routeOrders}/>
          </div>
          <div className='col-start-3 col-span-2 w-auto h-auto'>
            <RoutePoints data={analytics?.routeOrders}/>
          </div>
        </div>

        <div className='grid grid-cols-4 gap-3 mt-4'>
          <div className='col-start-0 col-span-2 w-auto h-auto'>
            <CityOrders data={analytics?.cityOrders}/>
          </div>
          <div className='col-start-3 col-span-2 w-auto h-auto'>
            <CityPoints data={analytics?.cityOrders}/>
          </div>
        </div>


        <div className='grid grid-cols-4 gap-3 mt-4'>
          <div className='col-start-0 col-span-2 w-auto h-auto'>
            <TeamOrders data={analytics?.teamOrders}/>
          </div>
          <div className='col-start-0 col-span-2 w-auto h-auto'>
            <TeamPoints data={analytics?.teamOrders}/>
          </div>
        </div>

        <div className='grid grid-cols-4 gap-3 mt-4'>
          <div className='col-start-0 col-span-2 w-auto h-auto'>
            <RepOrders data={analytics?.repOrders}/>
          </div>
          <div className='col-start-0 col-span-2 w-auto h-auto'>
            <RepPoints data={analytics?.repOrders}/>
          </div>
          </div>
    </div> : <Loader/>
  )
}


