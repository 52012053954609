import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import Loader from "../../../components/Loader"

export default function ManageRep({onClose,edit,onSubmit,submitting,updateData=null})
{
    const [manageData,setManageData] = useState(!updateData ? {firstName:'',lastName:'test',repArea:'testarea',repEmail:'',repContactNumber:'',repCity:'',repStatus:1,repTeam:'',repTarget:null,repRoutes:[]}:{repRoutes:[]})
    const [teams,setTeams] = useState([])
    const formName = 'editSalesRep';
    const [loading,setLoading] = useState(true)
    const [croppedImage,setCroppedImage] = useState()
    const [filteredCities,setFilteredCities] = useState([])
    const [routeWithCities,setRoutesWithCities] = useState([])


    const updateRepRoutes = (cityId, routeId) => {
      setManageData((prevData) => {
        if (!prevData.cities || prevData.cities.length === 0) {
          console.log('running outside (empty repRoutes)');
          return {
            ...prevData,
            cities: [
              {
                cityId,
                routes: [routeId],
              },
            ],
          };
        }
    
        const existingCityIndex = prevData.cities.findIndex(
          (city) => city.cityId === cityId
        );
    
        if (existingCityIndex !== -1) {
          console.log('running inside');
          // City exists, check for the route
          const existingRouteIndex = prevData.cities[
            existingCityIndex
          ].routes.indexOf(routeId);
    
          if (existingRouteIndex !== -1) {
            // Route exists, remove it
            const updatedRoutes = [
              ...prevData.cities[existingCityIndex].routes.slice(
                0,
                existingRouteIndex
              ),
              ...prevData.cities[existingCityIndex].routes.slice(
                existingRouteIndex + 1
              ),
            ];
    
            // Check if routes array is empty after removal
            if (updatedRoutes.length === 0) {
              // Remove the entire city
              return {
                ...prevData,
                cities: [
                  ...prevData.cities.slice(0, existingCityIndex),
                  ...prevData.cities.slice(existingCityIndex + 1),
                ],
              };
            }
    
            return {
              ...prevData,
              cities: [
                ...prevData.cities.slice(0, existingCityIndex),
                {
                  cityId,
                  routes: updatedRoutes,
                },
                ...prevData.cities.slice(existingCityIndex + 1),
              ],
            };
          } else {
            // Route doesn't exist, add it
            return {
              ...prevData,
              cities: [
                ...prevData.cities.slice(0, existingCityIndex),
                {
                  cityId,
                  routes: [...prevData.cities[existingCityIndex].routes, routeId],
                },
                ...prevData.cities.slice(existingCityIndex + 1),
              ],
            };
          }
        } else {
          // City doesn't exist, add a new entry
          console.log('running outside');
          return {
            ...prevData,
            cities: [
              ...prevData.cities,
              {
                cityId,
                routes: [routeId],
              },
            ],
          };
        }
      });
    };
    
useEffect(()=>{
    const fetchData = async () => {
      try {
        // Fetch teams
        setLoading(true)
        const teamsResponse = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/teams`);
        setTeams(teamsResponse.data.data);

        // Fetch cities with routes
        const routesWithCitiesResponse = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/routes-cities`);
        setRoutesWithCities(routesWithCitiesResponse.data.data);

        // Fetch representative info if in edit mode
        if (edit) {
          const repInfoResponse = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/salesrepresentative/${edit}`);
          setManageData({
            firstName: repInfoResponse.data.data.firstName,
            repEmail: repInfoResponse.data.data.repEmail,
            repContactNumber: repInfoResponse.data.data.repContactNumber,
            repCity: repInfoResponse.data.data.repCity,
            repStatus: parseInt(repInfoResponse.data.data.repStatus),
            repTeam: repInfoResponse.data.data.repTeam,
            lastName: 'test',
            repArea: 'test',
            repTarget: repInfoResponse.data.data.repTarget,
            cities: repInfoResponse.data.data.cities,
          });
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [edit]);

    useEffect(()=>
    {
      if(manageData.repTeam)
      {
        console.log('edit data',manageData)
        console.log('teams',teams)
        let index = teams.findIndex(item=>item.id === manageData.repTeam)
        console.log('team',manageData.repTeam)
        console.log('ibndex',index)
        let cities = routeWithCities.filter((obj) => teams[index].cities.includes(obj.cityId));
        setFilteredCities(cities)
        setManageData(data=>({...data}))
      }
    },[manageData.repTeam])



    return <><Popup size="xl" submitting={submitting} title={edit ? 'Update Sales Rep' : 'Create Sales Rep'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        {!loading ? <form onSubmit={(e)=>onSubmit(e,{...manageData,retailerShopImage:croppedImage})} id={formName} className='grid grid-cols-2 gap-y-0 w-full items-start'>
            <div className="grid grid-cols-2 gap-x-3 gap-y-0 w-full pr-4  items-start">
            <div>
                <label>Full Name</label>
                <Input type='text' placeholder={'Enter Full Name'} value={manageData.firstName} setValue={(value)=>setManageData(data=>({...data,firstName:value}))}/>
            </div>
            <div>
                <label>Email</label>
                <Input type='text' placeholder={'Enter Email Address'} value={manageData.repEmail} setValue={(value)=>setManageData(data=>({...data,repEmail:value}))}/>
            </div>
            <div>
                <label>Phone Number (+91)</label>
                <Input type='text' placeholder={'Enter Phone Number'} value={manageData.repContactNumber} setValue={(value)=>setManageData(data=>({...data,repContactNumber:value}))}/>
            </div>
            {/* <div>
                <label>City</label>
                <Select placeholder={'Select City'} customLabel={'cityName'} options={cities} customValue={'id'} value={manageData.repCity} setValue={(value)=>setManageData(data=>({...data,repCity:value}))}/>
            </div> */}
            <div>
                <label>Team</label>
                <Select placeholder={'Select Team'} customLabel={'teamName'} required={true} options={teams} customValue={'id'} value={manageData.repTeam} setValue={(value)=>setManageData(data=>({...data,repTeam:value}))}/>
            </div>
            <div>
                <label>Target</label>
                <Input type='text' placeholder={'Enter Target'} value={manageData.repTarget} setValue={(value)=>setManageData(data=>({...data,repTarget:value}))}/>
            </div>
            <div>
                <label>Status</label>
                <Select placeholder={'Select Status'} options={[{name:'Active',value:1},{name:'Inactive',value:0}]}  value={manageData.repStatus} setValue={(value)=>setManageData(data=>({...data,repStatus:value}))}/>
            </div>
            </div>
            <div className="h-[420px] overflow-scroll border-l border-x-gray-200 pl-4">
                {
                    filteredCities.map((item,index)=><CityItem manageData={manageData} updateRepRoutes={updateRepRoutes} item={item} key={index}/>)
                }
                {
                  routeWithCities.length <=0 ? <p>Select Team to update Routes</p> : null
                }
            </div>
        </form> : <div className="max-h-[420px] w-full justify-center items-center"><Loader/></div>}
    </Popup>
    </>
}


const CityItem = ({item,updateRepRoutes,manageData})=>
{
    return <div className="py-2 border-b border-b-gray-200 px-4">
        <p className="font-semibold text-sm">{item.cityName}</p>
        <div className="flex flex-wrap gap-2 mt-2">
        {
            item.routes.map((i,index)=>
            {
                return <RouteItem manageData={manageData} updateRepRoutes={updateRepRoutes} item={i} key={item.cityId} cityId={item.cityId}/>
            })
        }
        </div>
    </div>
}

const RouteItem = ({item,updateRepRoutes,cityId,manageData})=>
{
  const [isRouteSelected,setIsRouteSelected] = useState(false)
  useEffect(()=>
  {
    let check = manageData.cities?.some(
        (city) => city.cityId === cityId && city?.routes?.includes(item.routeId)
      );
      setIsRouteSelected(check)

  },[manageData])

    const handleRouteClick = () => {
        // Call the updateRepRoutes function to update the state
        updateRepRoutes(cityId, item.routeId);
      };

    return <div className={`py-2 border border-gray-200 px-2 rounded-full cursor-pointer ${isRouteSelected ? 'bg-[#2ac08b]' : 'bg-[#f3f3f3]'}`} onClick={handleRouteClick}>
        <p className={`font-medium tracking-tight text-xs ${isRouteSelected ? 'text-white' : ''}`}>{item.routeName}</p>
    </div>
}