import { useEffect, useState } from "react"
import { authAxios } from "../../../helpers/axios"
import SlidePopup from "../../../components/SlidePopup"
import FilterSelect from "../../../components/FilterSelect"

export default function DistributorFilter({filters,onSubmit,onClose,clearFilter})
{
    const [localFilters,setLocalFilters] = useState({routeId:null,cityId:null,status:null})
    const [cities,setCities] = useState([])
    const [routes,setRoutes] = useState([])
    const [reps,setReps] = useState([])
    const [loading,setLoading] = useState(true)


    useEffect(()=>
    {
        console.log('local filters',localFilters)
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCities(res.data.data)
            setLoading(false)
            setLocalFilters(filters)
        }
        getCities()
        setLocalFilters(filters)
    },[])

    async function getRoutes(){
        let query =''
        if(localFilters.cityId) query+=`routeCity=${localFilters.cityId.id}`
        let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?${query}`)
        if(res.data.data)  setRoutes(res.data.data)
    }
    useEffect(()=>
    {
        if(localFilters.cityId !== '') getRoutes()
        else {
            setLocalFilters(data=>({...data,routeId:''}))
        }
    },[localFilters.cityId])

    
    
    return <><SlidePopup title={'Distributor Filters'} formName={'filterDistributor'} onClose={onClose}>
        {
            loading ?  <p>Loading</p>: 
            <div className="w-full">
            <div className="grid grid-cols-2 gap-x-6 gap-y-6">
                <div>
                    <label>City</label>
                    <div className='h-[100%]'>
                    <FilterSelect placeholder={'Select city'} options={cities} value={localFilters.cityId} customLabel={'cityName'} customValue={'id'} setValue={(value)=>setLocalFilters(data=>({...data,cityId:value}))} padding={false}/>
                </div>
                </div>
                <div>
                    <label>Route</label>
                    <div className='h-[100%]'>
                    <FilterSelect placeholder={'Select route'} options={routes} value={localFilters.routeId} customLabel={'routeName'} customValue={'id'} setValue={(value)=>setLocalFilters(data=>({...data,routeId:value}))} padding={false}/>
                </div>
                </div>
                <div>
                    <label>Status</label>
                    <div className='h-[100%]'>
                    <select placeholder="Select Status" defaultValue={localFilters.status} className={`min-w-[120px] px-2 pr-0`} value={localFilters.status} onChange={(e)=>setLocalFilters(data=>({...data,status:e.target.value}))}>
                        <option value=''>All</option>
                        <option value={1}>Active</option>
                        <option value={0}>Inactive</option>
                    </select>
                </div>
                </div>
                <div className='flex justify-end mt-4  w-full col-span-2'>
                    <button type='button' className='btn-md-disabled' onClick={clearFilter}>Clear Filters</button>
                    <button type='submit' className='ml-4 btn-md' onClick={()=>onSubmit(localFilters)}>Filter Distributors</button>
                </div>
            </div>
        </div>
        }
    </SlidePopup>

    </>
}



