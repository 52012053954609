import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/SearchInput'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ErrorToast, InfoToast } from '../../../helpers/toasters'
import { LIMIT } from '../../../helpers/constants'
import RightIcon from '../../../images/right.svg'
import SortIcon from '../../../images/sort.svg'
import FilterIcon from '../../../images/filter.svg'
import FilterActiveIcon from '../../../images/filteractive.svg'
import FilterRoute from '../components/RouteFilter'
import { getDateFormat, getTimeFormat } from '../../../helpers/utils'
import Select from '../../../components/Select'
import Input from '../../../components/Input'
import Popup from '../../../components/Popup'
import SmallPopup from '../../../components/SmallPopup'

export default function Hsns({setShowManage,showManage}) {

    const [hsns,setHsns] = useState([])
    const [showDeleteHsn,setShowDeleteHsn] = useState(false)
    const [deleting,setDeleting] = useState(false)

    async function getHsns(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/hsn`)
            if(res.data.data)
            {
                setHsns(res.data.data)
            }
        } catch (error) {
            console.log(error)
            toast('Error getting orders')
        }
    } 

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res;
            console.log('sbm',data)
            if(showManage.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/hsn/${data.id}`,{...data})  
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/hsn`,{...data}) 
                
            }
            if(res.data.data)
            {
                if(showManage.edit)
                {
                    setHsns(hsns=>{
                        let prev = [...hsns]
                        let itemIndex = prev.findIndex(item=>item.id === showManage.edit)
                        console.log('itemIndex',itemIndex,'res',res.data.data.data)
                        prev[itemIndex] = {...prev[itemIndex],hsnName:res.data.data.data.hsnName}
                        return prev;
                    })
                }
                else setHsns(hsns=>([...hsns,{...res.data.data.data}]))
                InfoToast(showManage.edit ? 'HSN Updated' : 'HSN Created',{position: toast.POSITION.BOTTOM_CENTER,hideProgressBar:true})
                setShowManage({type:null,status:false,edit:null})
            }
            else toast('error creating HSN')
        } catch (error) {
            console.log(error)
            ErrorToast(error.response.data.error.hsnName)
        }
    }

    const onCityDelete = async(e)=>
    {
        try 
        {
            setDeleting(true)
            await authAxios.delete(`${process.env.REACT_APP_BASE_URL}/hsn/${showDeleteHsn}`) 
            getHsns()
            setShowDeleteHsn(false)
            setDeleting(false)
            InfoToast('HSN Deleted')
        } catch (error) {
            setDeleting(false)
            ErrorToast('HSN Deletion Failed')
            
        }
    }


    useEffect(()=>
    {
        getHsns();
    },[])


  return (
    <div className='flex flex-col w-full'>
        <div className='bg-white w-full flex-1 flex overflow-scroll'>
            <table className=' table-auto w-full flex-1 '>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>HSN Number</p></td>
                    <td><p className='cursor-pointer flex items-center hover:text-black'>Action</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        hsns.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.hsnName}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    <button className='btn-xs' onClick={()=>setShowManage({type:'hsns',status:true,edit:item.id})}>Edit</button>
                                    <button className='btn-xs btn-red ml-1' onClick={()=>setShowDeleteHsn(item.id)}>Delete</button>
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        { showManage.status && showManage.type ==='hsns' ? <ManageHsn setShow={setShowManage} onSubmit={onSubmit} edit={showManage.edit} /> : null}
        {showDeleteHsn ? <DeleteHsn submitting={deleting} onSubmit={onCityDelete} onClose={setShowDeleteHsn}/> : null}
    </div>
  )
}

const DeleteHsn = ({onSubmit,onClose,submitting})=>
{
    return <SmallPopup submitting={submitting} title={'Delete HSN'} onClose={onClose} onSubmit={onSubmit} submitTitle={'Yes, Delete'}>
        <div>
            <p className="text-sm">Are you sure you want to delete the HSN?</p>
        </div>
    </SmallPopup>
}



const ManageHsn = ({setShow,onSubmit,edit=false})=>
{
    const [hsn,setHsn] = useState({id:null,hsnName:''})
    const [loading,setLoading] = useState(edit ? true : false)
    useEffect(()=>
    {
        async function getHsnInfo(){
            if(edit)
            {
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/hsn/${edit}`)
                console.log(res.data.data)
                setHsn({id:res.data.data.id,hsnName:res.data.data.hsnName})
                setLoading(false)
            }
        }
        getHsnInfo()
    },[])
    return loading ? 'loading' :<Popup onClose={()=>setShow({type:null,status:false})}  title={edit ?  'Edit Hsn' : 'Add Hsn'} submitTitle={edit ? 'Update' : 'Add'} formName={'createHsn'}>
        <form className='w-full' onSubmit={(e)=>onSubmit(e,edit ? hsn : {hsnName:hsn.hsnName})} id="createCity">
            <div>
                <label>Hsn Name</label>
                <Input placeholder={'Enter Hsn Name'} value={hsn.hsnName} setValue={(value)=>setHsn(city=>({...city,hsnName:value}))} required={true}/>
            </div>
        </form>
    </Popup>
}