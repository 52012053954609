import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SearchInput from '../../components/SearchInput'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import FilterSelect from '../../components/FilterSelect'
import { useNavigate } from 'react-router-dom'
import RepLeaderboard from './components/RepLeaderboard'
import TabGroup from '../../components/TabGroup'
import TeamLeaderboard from './components/TeamLeaderboard'
import OutsideFilter from '../../components/OutsideFilter'
import DatePicker from 'react-datepicker'

export default function Leaderboard() {

    const [reps,setReps] = useState([])
    const [showManage,setShowManage] = useState({status:false,edit:null})
    const [activeTab,setActiveTab] = useState('rep')
    const [month,setMonth] = useState(new Date())
    

    // function getCurrentMonth() {
    //     const now = new Date();
    //     const monthNames = [
    //         'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    //         'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    //     ];
    //     const year = now.getFullYear();
    //     const month = monthNames[now.getMonth()];
    
    //     return `${month}-${year}`;
    // }

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowManage({status:true,edit:null})}>Add Retailer</button>
        </div>
    }

    async function getReps(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/salesrepresentative?populate=true`)
            if(res.data.data) setReps(res.data.data)
        } catch (error) {
            toast('Error getting retailer')
        }
    }
    useEffect(()=>
    {
        async function getReps(){

            try 
            {
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/salesrepresentative?populate=true`)
                console.log('salse',res.data.data)
                if(res.data.data) setReps(res.data.data)
            } catch (error) {
                toast('Error getting sales representatives')
            }
        }
        getReps();
    },[])

    // useEffect(()=>
    // {
    //     async function getCities(){
    //         let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
    //         setCityFilters(res.data.data)
    //     }
    //     getCities()
    // },[])

    // useEffect(()=>
    // {
    //     async function getRoutes(){
    //         let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/route?routeCity=${selectedFilters.city}`)
    //         if(res.data.data)  setRouteFilters(res.data.data)
    //     }
    //     if(selectedFilters.city !== '') getRoutes()
    //     else {
    //         setRouteFilters([]);
    //         setSelectedFilters(data=>({...data,route:''}))
    //     }
    // },[selectedFilters.city])

    const onSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            console.log(data)
            // let base64Image = await getBase64Image(data.retailerShopImage)
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/salesrepresentative`,{...data}) 
            if(res.data.data)
            {
                await getReps()
                toast.info('Sales Rep Added')
                setShowManage({edit:null,status:false})
            }
            else toast('error updating retailer')
        } catch (error) {
            console.log(error)
            toast(error.response)
        }
    }


  return (
    <>
        <Header title={'Leaderboard'} RightContent={()=>{}}/>
        <div className='flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
            <div>
               <TabGroup activeTab={activeTab} setActiveTab={setActiveTab} options={[{label:'Sales Rep.',value:'rep'},{label:'Team',value:'team'}]}/>
            </div>
            <div className='flex flex-1'>
                {/* <OutsideFilter placeholder={'Select Month'} options={routeFilters} value={selectedFilter} customLabel={'routeName'} customValue={'id'} setValue={(value)=>setSelectedFilter(value)} padding={false}/> */}
                <DatePicker
      selected={month}
      className={'h-full w-[100px] text-[13px] cursor-pointer border-l px-2'}
      onChange={(date) => setMonth(date)}
      dateFormat="MMM-yyyy"
      showMonthYearPicker
      showFullMonthYearPicker
      showTwoColumnMonthYearPicker
      onKeyDown={(e) => {
        e.preventDefault();
     }}
    />
            </div>
        </div>
        <div className='bg-white w-full flex-1 flex overflow-scroll'>
            {activeTab === 'rep' ? <RepLeaderboard month={month}/> : <TeamLeaderboard month={month} />}
            
        </div>
        {/* { showEdit ? <ManageDistributor onClose={setShowEdit} onSubmit={onSubmit} update={true} updateData={distributorInfo}/> : null} */}
    </>
  )
}

