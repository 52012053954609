import React, { useEffect, useState } from 'react'
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import Logo from '../images/logo.png'
// import Menu from '../images/menu-icon.svg'
import DashboardIcon from '../images/dashboard.svg'
import OrdersIcon from '../images/orders.svg'
import SalesRepIcon from '../images/sales-rep.svg'
import ProductsIcon from '../images/products.svg'
import RouteIcon from '../images/route.svg'
import SettingsIcon from '../images/settings.svg'
import LogoutIcon from '../images/logout.svg'
import LeaderboardIcon from '../images/leaderboard.svg'
import DistributorIcon from '../images/distributor.svg'
import TeamIcon from '../images/team.svg'
import UpIcon from '../images/up.svg'
import RetailerIcon from '../images/retailers.svg'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../redux/slice/authSlice'


const SidebarMenuSubItem = ({active,setActive,data,collapsed})=>
{
    return  <div className={`pl-4 menu-sub-item relative my-3 mb-2 ${collapsed ? 'pl-0' : 'pl-4'}`}>
        <NavLink to={data.url} className={({ isActive, isPending }) =>
    isActive ? " inline-block menu-sub-active group active" : "inline-block"
  }>
            <div className={`w-auto mx-auto flex rounded-md items-center group-[.menu-sub-active]:bg-[#313B53] transition-all duration-[300ms] ${collapsed ? 'ml-4 px-4' : 'ml-10 px-4'}`}>
            <div className='py-3'>
            </div>
            <div className={`${!collapsed ? 'ml-3' :'ml-0'}`}>
                <p className={`text-[13px] whitespace-nowrap font-normal transition-all duration-[300ms] text-gray-300 group-[.active]:text-white ${!collapsed ? 'w-auto opacity-100' :'w-0 overflow-hidden opacity-0'}`}>{data.label}</p>
            </div>
        </div>
        </NavLink>
        </div>
}

const SidebarMenuItem = ({active,setActive,data,collapsed})=>
{
    return  <div className={`menu-item transition-all relative  ${active === data.id && data.childrens && data.childrens.length >0 ? 'menu-item-active border-b-stone-700 border-b-[1px]' : ''}`} onClick={()=>setActive(data.id)}>
        <NavLink to={data.url} className={({ isActive, isPending }) =>
        {
            if(isActive) setActive(data.id);
            return isActive ? " block group parent-active opacity-100" : "filter grayscale"
        }
}>
            <div className={`w-full flex px-8 items-center `}>
      {active === data.id ? <div className='h-full w-[6px] bg-[#2ac08b] absolute top-0 left-0 rounded-r-full'></div> : null}
                <div className='w-full flex items-center'>
                    <div className='py-4'>
                        <img src={data.icon} className='w-5 h-5 '/>
                    </div>
                    {!collapsed ? <div className='ml-4'>
                        <p className='text-[14px] tracking-[-.15px] font-medium text-[#2ac08b] '>{data.label}</p>
                        {/* <p className='text-[13px] tracking-[-.15px] font-medium text-[#757575] hover:text-[#2ac08b] group-[.parent-active]:text-[#2ac08b]'>{data.label}</p> */}
                    </div> : null}
                </div>
                {!collapsed && data.childrens && data.childrens.length > 0 ? <div className=''>
                    <img src={UpIcon} className={`w-3 h-3 ${active !== data.id ? 'origin-center rotate-180' : ''}`}/>
                </div> : null}
        </div>
        </NavLink>
        {
            data.childrens && data.childrens.length > 0 ? <div className={`overflow-hidden transition-all ${active !== data.id ? 'h-0 ' : 'h-full py-4'}`}>
            {
                data.childrens.map((item,index)=>
                {
                    return <SidebarMenuSubItem data={item} key={index} active={active} collapsed={collapsed}/>
                })
            }
        </div> : null
        }
        </div>
}
export default function Sidebar() {

    const [activeMenu,setActiveMenu] = useState(1)
    const [collapsed,setCollapsed] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const authInfo = useSelector(state=>state.auth)

    const {pathname} = useLocation();

    useEffect(()=>{
        if(!authInfo.isLoggedIn || !authInfo.token) return navigate('/login')
    },[authInfo])

    const onLogout = ()=>
    {
        dispatch(logout())
    }

    const data = [
        {
            id:1,
            label:'Dashboard',
            icon:DashboardIcon,
            childrens:null,
            url:'/'
        },
        {
            id:2,
            label:'Orders',
            icon:OrdersIcon,
            childrens:null,
            url:'/orders'
        },
        {
            id:3,
            label:'Sales Representative',
            icon:SalesRepIcon,
            url:'/sales-representatives',
            // childrens:
            // [
            //     {
            //         id:31,
            //         parent:3,
            //         label:'Manage Loan Products',
            //         icon:LoanIcon,
            //         url:'loans/'
            //     },
            //     {
            //         id:32,
            //         parent:3,
            //         label:'Loan Providers',
            //         icon:LoanIcon,
            //         url:'/loans/loan-providers'
            //     },
            //     {
            //         id:33,
            //         parent:3,
            //         label:'Loan Premium',
            //         icon:LoanIcon,
            //         url:'/loans/loan-premium'
            //     }
            // ],
        },
        {
            id:10,
            label:'Teams',
            icon:TeamIcon,
            childrens:null,
            url:'/teams'
        },
        {
            id:9,
            label:'Leaderboard',
            icon:LeaderboardIcon,
            childrens:null,
            url:'/leaderboard'
        },
        {
            id:4,
            label:'Distributors',
            icon:DistributorIcon,
            childrens:null,
            url:'/distributors'
        },
        {
            id:5,
            label:'Retailers',
            icon:RetailerIcon,
            childrens:null,
            url:'/retailers'
        },
        {
            id:6,
            label:'Products',
            icon:ProductsIcon,
            childrens:null,
            url:'/products'
        },
        {
            id:7,
            label:'Route Schedule',
            icon:RouteIcon,
            childrens:null,
            url:'/route-schedule'
        },
        {
            id:8,
            label:'Settings',
            icon:SettingsIcon,
            childrens:null,
            url:'/settings'
        }
        
    ]
  return (
    <div className='w-auto h-screen flex transition-all'>
        <div className={`hidden md:block bg-white border-r border-r-[#E3E3E3]shrink-0 max-w-[280px] transition-all duration-[300ms] w-full h-screen overflow-scroll`}>
            <div className='flex justify-between px-8 py-4 items-center transition-all duration-[300ms]'>
                {!collapsed ? <img src={Logo} className='h-10 w-auto'/> : null}
                <div className='h-[32px] w-[32px] '>
                    {/* <img src={Menu} className='h-[24px] w-[24px] cursor-pointer block' onClick={()=>setCollapsed(!collapsed)}/> */}
                </div>
            </div>
            <div className='w-auto mt-8'>
                {
                    data.map((item,index)=>
                    {
                        return <SidebarMenuItem collapsed={collapsed} data={item} key={index} setActive={setActiveMenu} active={activeMenu}/>
                    })
                }
                <div className={`w-full flex px-8 items-center cursor-pointer  filter grayscale hover:grayscale-0`} onClick={()=>onLogout()}>
                <div className='w-full flex items-center'>
                    <div className='py-4'>
                        <img src={LogoutIcon} className='w-5 h-5 '/>
                    </div>
                    <div className='ml-4'>
                        <p className='text-sm tracking-[-.15px] font-medium text-[#2ac08b] '>Logout</p>
                    </div>
                </div>
        </div>
            </div>
        </div>
        <div className={`md:hidden fixed z-[99]  w-screen bg-[#0F172A] h-auto  transition-all duration-[300ms] ${collapsed ? 'w-[80px]': 'w-full'}`}>
        <div className='flex justify-between px-8 py-5 items-center transition-all duration-[300ms]'>
                {/* {!collapsed ? <img src={Logo} className='h-6 w-auto'/> : null} */}
                <div className='h-[18px] w-[18px] '>
                    {/* <img src={Menu} className='h-[18px] w-[18px] cursor-pointer block' onClick={()=>setCollapsed(!collapsed)}/> */}
                </div>
            </div>
        </div>
        {/* <div className='flex-1 mt-16 md:mt-0 h-screen overflow-hidden'> */}
        <div className='flex flex-col mt-16 md:mt-0 h-screen overflow-hidden w-full'>
            <Outlet/>
        </div>
    </div>
  )
}
