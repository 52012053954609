import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { authAxios } from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import ManageRetailer from './components.jsx/ManageRetailer'
import { InfoToast } from '../../helpers/toasters'
import RetailerOrder from './components.jsx/RetailerOrder'

export default function RetailerInfo() {

    const {id} = useParams();
    const [orders,setOrders] = useState([])
    const [retailerInfo,setRetailerInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [activeTab,setActiveTab] = useState('orders')

    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowEdit(true)}>Edit Retailer</button>
        </div>
    }

    async function getRetailer(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/retailer/${id}?populate=true`)
            if(res.data.data) setRetailerInfo(res.data.data)
            let ordersRes = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/orders?retailerId=${id}&populate=true`)
            if(ordersRes.data.data) setOrders(ordersRes.data.data)
        } catch (error) {
            toast('Error getting Orders')
        }
    }
    useEffect(()=>
    {
        getRetailer();
    },[])

    const onSubmit = async(e,data,croppedImage)=>
    {
        try 
        {
            e.preventDefault()

            let imageRes;
            if(croppedImage)
            {
                let formData = new FormData();
                formData.append('image',croppedImage)
                imageRes = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/image`,formData,{                headers: {
            'content-type': 'multipart/form-data'}})
            } 

            let updateData = croppedImage ? {...data,retailerImage:imageRes.data.data} : {...data};
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/retailer/${id}`,updateData); 
            if(res.data.data)
            {
                await getRetailer()
                setShowEdit(false)
                InfoToast('Retailer Updated')
            }
            else InfoToast('error updating retailer')
        } catch (error) {
            console.log(error)
            InfoToast(error.response)
        }
    }

  return (
    <>
        <Header title={retailerInfo.retailerShopName} RightContent={RightContent}/>
        <div className=' flex justify-between  border-b border-[#e3e3e3] bg-white'>
            <div>
               <TabGroup setActiveTab={setActiveTab} activeTab={activeTab} options={[{label:'Orders',value:'orders'},{label:'Profile',value:'profile'}]}/>
            </div>
        </div>
        <div className='flex flex-1 overflow-scroll bg-white w-full '>
            {activeTab === 'orders' ? <RetailerOrder id={id}/> : <ProfileInfo info={retailerInfo}/>}
            
        </div>
        { showEdit ? <ManageRetailer onClose={setShowEdit} onSubmit={onSubmit} edit={true} updateData={retailerInfo}/> : null}
    </>
  )
}

const ProfileInfo = ({info})=>
{
    
    return <div className='flex justify-start items-start h-[100%] w-full py-12 px-6'>
        <div className='mb-4 w-[120px] h-[120px] mr-20'>
            <img src={info?.retailerImage} className='w-[120px] h-[120px] rounded-full'/>
        </div>
        <div className='grid grid-cols-4 gap-6'>
            <div>
                <label>Shop Name</label>
                <p className='text-sm'>{info?.retailerShopName}</p>
            </div>
            <div>
                <label>Owner Name</label>
                <p className='text-sm'>{info?.retailerName}</p>
            </div>
            <div>
                <label>Retailer GSTIN</label>
                <p className='text-sm'>{info.retailerGst ? info.retailerGst : 'Not Available'}</p>
            </div>
            <div>
                <label>Contact Number</label>
                <p className='text-sm'>{info?.retailerName}</p>
            </div>
            <div>
                <label>City</label>
                <p className='text-sm'>{info?.retailerCity?.cityName}</p>
            </div>
            <div>
                <label>Route</label>
                <p className='text-sm'>{info?.retailerRoute?.routeName}</p>
            </div>
            <div>
                <label>Area</label>
                <p className='text-sm'>{info?.retailerArea}</p>
            </div>
            <div>
                <label>Address</label>
                <p className='text-sm'>{info?.retailerAddress}</p>
            </div>
            <div>
                <label>GST</label>
                <p className='text-sm'>{info?.retailerGst ? info?.retailerGst : 'Not Available'}</p> 
            </div>
            {info.retailerLat ? <div>
                <label>Map Location</label>
                <a href={`https://maps.google.com/?q=${info.retailerLat},${info.retailerLong}`} className='text-sm text-blue-600 underline' target='_blank'>Map Link</a>
            </div> : null}
        </div>
    </div>
}
