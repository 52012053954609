import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Sidebar from './components/Sidebar';
import Login from './pages/auth/Login';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Order from './pages/orders/Order';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import {store, persistor} from './redux/store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './pages/dashboard/Dashboard';
import Distributor from './pages/distributor/Distributor';
import Settings from './pages/settings/Settings';
import DistributorInfo from './pages/distributor/DistributorInfo';
import Retailer from './pages/retailer/Retailer';
import RetailerInfo from './pages/retailer/RetailerInfo';
import SalesRep from './pages/sales/SalesRep';
import Product from './pages/products/Product';
import Teams from './pages/sales/Teams';
import SalesRepInfo from './pages/sales/SalesRepInfo';
import Leaderboard from './pages/leaderboard/Leaderboard';
import Schedule from './pages/schedule/Schedule';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "react-datepicker/dist/react-datepicker.css";
import 'react-tooltip/dist/react-tooltip.css'
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';

const router = createBrowserRouter([
  {
    path:'/login',
    element:<Login/>
  },
  {
    path:'/forgot-password',
    element:<ForgotPassword/>
  },
  {
    path:'/reset-password',
    element:<ResetPassword/>
  },
  {
    path:'/',
    element:<Sidebar/>,
    children:[
      {
        path:'/',
        index:true,
        element:<Dashboard/>
        // element:
      },
      {
        path:'orders',
        element:<Order/>
      },
      {
        path:'products',
        element:<Product/>
      },
      {
        path:'sales-representatives',
        element:<SalesRep/>,
      },
      {
        path:'leaderboard',
        element:<Leaderboard/>,
      },
      {
        path:'route-schedule',
        element:<Schedule/>,
      },
      {
        path:'sales-representatives/:id',
        element:<SalesRepInfo/>,
      },
      {
        path:'teams',
        element:<Teams/>,
      },
      {
        path:'retailers',
        element:<Retailer/>,
      },
      {
        path:'retailers/:id',
        element:<RetailerInfo/>,
      },
      {
        path:'distributors',
        element:<Distributor/>,
      },
      {
        path:'distributors/:id',
        element:<DistributorInfo/>,
      },
      {
        path:'settings',
        element:<Settings/>
      },
      // {
      //   path:'/activities'
      // }
    ]
  },
])

const contextClass = {
  success: "bg-blue-600",
  error: "bg-red-600",
  info: "bg-green-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <RouterProvider router={router}/>
    </PersistGate>
  </Provider>
  <ToastContainer toastClassName={({ type }) => contextClass[type || "default"] +
        " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
      }/>
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
